import { EyeCrossedIcon, EyeIcon } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

type QRCodeWrapperType = {
    setIsQrCodeShown: React.Dispatch<React.SetStateAction<boolean>>;
    isQrCodeShown: boolean;
    handleOnChangeEvent: (e: React.ChangeEvent<HTMLInputElement>) => void;
    inputRef: React.RefObject<HTMLInputElement>;
};

export function QRCodeWrapper({
    isQrCodeShown,
    setIsQrCodeShown,
    handleOnChangeEvent,
    inputRef,
}: QRCodeWrapperType): JSX.Element {
    const { isPrm } = useSnapshot(kioskStore);

    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ gap: isPrm ? "50px" : "100px" }}
        >
            <img
                src="./images/qr-code.png"
                alt="qrCode"
                style={{
                    height: isPrm ? "200px" : "330px",
                    width: isPrm ? "200px" : "330px",
                }}
            />
            <div
                style={{
                    borderBottom: "1px solid #00000029",
                }}
            >
                <input
                    className="form-input text-center qr-code__clz"
                    type={isQrCodeShown ? "text" : "password"}
                    autoFocus
                    placeholder={t("QR CODE")}
                    onChange={handleOnChangeEvent}
                    ref={inputRef}
                />
                {isQrCodeShown === false ? (
                    <EyeIcon
                        className="mb-3 d-none"
                        height={30}
                        width={30}
                        onClick={() => {
                            setIsQrCodeShown(!isQrCodeShown);
                        }}
                    />
                ) : (
                    <EyeCrossedIcon
                        className="mb-3 d-none"
                        height={30}
                        width={30}
                        onClick={() => {
                            setIsQrCodeShown(!isQrCodeShown);
                        }}
                    />
                )}
            </div>
        </div>
    );
}
