import { GODIA_LOYALTY } from "@constants";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { syncStore } from "@components/Synchronization/store";

import { kioskStore as store } from "@pages/Kiosk/store";

export default function SummaryHeader() {
    const { summaryHeaderBravoText, summaryHeaderLoyaltyPointText } =
        useSelector((state) => state.settingSlice.summary, shallowEqual);
    const { orderFidelity, totalPrice } = useSelector(
        (state) => state.orderSlice.order,
        shallowEqual
    );
    const { isUserAuthenticated, customerLanguage, user } = useSnapshot(store);
    const { selectedShopApp } = useSnapshot(syncStore);

    const godiaOrderFidelity = Math.round(totalPrice * user.pointRatio);

    const totalFidelity =
        selectedShopApp.fidelityType === GODIA_LOYALTY
            ? godiaOrderFidelity
            : orderFidelity;
    if (!isUserAuthenticated) return null;
    return (
        <div className="header-text pl-4">
            <span
                style={{
                    font: "normal normal bold 40px/54px Nunito",
                }}
            >
                {
                    summaryHeaderBravoText.languages[customerLanguage.name]
                        .content
                }
            </span>
            <br />
            <span
                style={{
                    font: "normal normal 400 30px/40px Segoe UI",
                }}
            >
                {`${
                    summaryHeaderLoyaltyPointText.languages[
                        customerLanguage.name
                    ].content
                } `}
                {`${totalFidelity} ${t("points")}`}
            </span>
        </div>
    );
}
SummaryHeader.propTypes = {
    summaryHeaderBravoText: PropTypes.object,
    summaryHeaderLoyaltyPointText: PropTypes.object,
};
