import {
    BACK_BUTTON_ROLE,
    CATALOGUE_MODE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { t } from "i18next";
import { SpinningCircles } from "svg-loaders-react";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import {
    getAlertActionButtonImageSrc,
    getImageContentById,
} from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import { setNavigationIndex, kioskStore as store } from "@pages/Kiosk/store";

import "./index.css";

type PropsType = {
    handleClearClickAndCollectInput: () => void;
    setIsClickAndCollectSalesModeDeactivated: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setIsQrCodeCorrectOrderNotProcessedModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    isQrCodeCorrectOrderNotProcessedModalOpened: boolean;
    isClickAndCollectSalesModeDeactivated: boolean;
};
export function QrCodeCorrectOrderNotProcessedModalWrapper({
    handleClearClickAndCollectInput,
    setIsClickAndCollectSalesModeDeactivated,
    setIsQrCodeCorrectOrderNotProcessedModalOpened,
    isQrCodeCorrectOrderNotProcessedModalOpened,
    isClickAndCollectSalesModeDeactivated,
}: PropsType): JSX.Element {
    const {
        isPrm,
        navigationIndex,
        project: {
            template: {
                content: { actionsButtons },
            },
        },
    } = useSnapshot(store);

    const alertImgSrc = getAlertActionButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );

    const backButton = (
        actionsButtons as ActionsButtonsDesignType
    ).items.filter((item) => {
        return item.role === BACK_BUTTON_ROLE;
    })[0];

    const backImageSrc = getImageContentById(backButton.id);

    function handleBackButton() {
        setIsClickAndCollectSalesModeDeactivated(false);
        setIsQrCodeCorrectOrderNotProcessedModalOpened(false);
        setNavigationIndex(navigationIndex - 1);
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isQrCodeCorrectOrderNotProcessedModalOpened}
            onRequestClose={() => {
                setIsQrCodeCorrectOrderNotProcessedModalOpened(false);
                handleClearClickAndCollectInput();
            }}
            contentLabel="qr-code -incorrect"
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: "15px solid #040404",
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    overflow: "hidden",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "400px" }}
            >
                {isClickAndCollectSalesModeDeactivated ? (
                    <img src={alertImgSrc} />
                ) : (
                    <img src="./images/withdrawalC&C.png" />
                )}
            </div>
            <div
                className="d-flex justify-content-center align-items-center text-center w-100"
                style={{ height: "250px", fontSize: "55px" }}
            >
                {isClickAndCollectSalesModeDeactivated
                    ? t("Order sale mode is not supported by the terminal")
                    : t("Click & Collect pickup")}
            </div>

            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "400px" }}
            >
                {isClickAndCollectSalesModeDeactivated ? (
                    t("Please contact a manager")
                ) : (
                    <SpinningCircles width="180px" height="180px" />
                )}
            </div>
            <div
                className="d-flex justify-content-center align-items-end"
                style={{ height: "200px" }}
            >
                {isClickAndCollectSalesModeDeactivated ? (
                    VALIDATED_EXTENSION_FILE.includes(
                        backImageSrc.slice(-4)
                    ) ? (
                        <img
                            src={backImageSrc}
                            alt="backImageSrc"
                            style={{
                                height: isPrm ? "70" : "87px",
                                width: isPrm ? "281px" : "350px",
                            }}
                            onClick={() => {
                                handleBackButton();
                                handleClearClickAndCollectInput();
                            }}
                        />
                    ) : (
                        <DynamicSvgComponent
                            nameSvg={backImageSrc}
                            width={isPrm ? "281px" : "350px"}
                            height={isPrm ? "70" : "87px"}
                            onClick={() => {
                                handleBackButton();
                                handleClearClickAndCollectInput();
                            }}
                        />
                    )
                ) : (
                    <span> {t("Please wait...")}</span>
                )}
            </div>
        </Modal>
    );
}
