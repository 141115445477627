import { StyledModal } from "@aureskonnect/react-ui";
import { VALIDATED_EXTENSION_FILE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { ModalBody } from "reactstrap";

import { setIsTechProblemModalOpened, store } from "@store";

import {
    getAlertActionButtonImageSrc,
    getBackButtonImgeSrc,
    handleLedControl,
} from "@helpers/general";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function TechnicalProblemModal(): JSX.Element {
    const { techProblemModal } = useSnapshot(store);
    const {
        project: {
            template: {
                content: { actionsButtons },
            },
        },
        isLedActive,
        connectedLedPort,
        navigationIndex,
    } = useSnapshot(kioskStore);

    const backImageSrc = getBackButtonImgeSrc(
        actionsButtons as ActionsButtonsDesignType
    );

    const alertImgSrc = getAlertActionButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );

    function handleOnClickEvent() {
        if (isLedActive) {
            if (navigationIndex === 0) {
                handleLedControl(connectedLedPort, "Green");
            } else {
                handleLedControl(connectedLedPort, "Orange");
            }
        }
        setIsTechProblemModalOpened(false);
    }
    React.useEffect(() => {
        if (isLedActive && techProblemModal.isTechProblemModalOpened) {
            handleLedControl(connectedLedPort, "Red");
        }
    }, [techProblemModal]);
    return (
        <StyledModal
            className="tech-problem-modal__clz"
            centered={true}
            isOpen={techProblemModal.isTechProblemModalOpened}
        >
            <ModalBody className="d-flex align-items-center justify-content-between flex-column py-5">
                <FlexboxGrid
                    justifyContentCentered={true}
                    alignItemsCentered={true}
                    gap="46px"
                    className="flex-column"
                >
                    <img
                        alt=""
                        src={alertImgSrc}
                        style={{
                            height: "124px",
                            width: "144px",
                            marginTop: "30px",
                        }}
                    />
                    <h2
                        className="text-center"
                        style={{
                            font: "normal normal  35px/42px Segoe UI",
                            letterSpacing: "2px",
                            color: "#171717",
                        }}
                    >
                        {t("The terminal is not available")}
                    </h2>
                </FlexboxGrid>
                <div
                    className="mt-5 d-flex flex-column align-items-center "
                    style={{
                        font: "normal normal  30px/42px Segoe UI",
                        letterSpacing: "0.17px",
                        textAlign: "center",
                    }}
                >
                    {techProblemModal.errorCode}
                </div>

                <div className="mt-3 d-flex justify-content-center align-items-end w-100">
                    {VALIDATED_EXTENSION_FILE.includes(
                        backImageSrc.slice(-4)
                    ) ? (
                        <img
                            src={backImageSrc}
                            alt="backImageSrc"
                            style={{
                                height: "80px",
                                width: "350px",
                                pointerEvents: "auto",
                                opacity: 1,
                            }}
                            onClick={handleOnClickEvent}
                        />
                    ) : (
                        <DynamicSvgComponent
                            nameSvg={backImageSrc}
                            width={"350px"}
                            height={"80px"}
                            pointerEvents={"auto"}
                            opacity={"1"}
                            onClick={handleOnClickEvent}
                        />
                    )}
                </div>
            </ModalBody>
        </StyledModal>
    );
}
