import classNames from "classnames";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { PreviewTopBannerBackgroundWrapper } from "@components/common/PreviewTopBannerBackgroundWrapper";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function LogoWrapper(): JSX.Element {
    const {
        isLogoActive,
        isPrm,
        project: { template },
        navigationIndex,
        kioskWays,
        isTopBannerActive,
    } = useSnapshot(kioskStore);

    const logoSrc = getImageContentById(
        (template.content.generalDesign as GeneralDesignType).logo.id as string
    );

    return (
        <div className="d-flex justify-content-center align-items-center">
            {isTopBannerActive && (
                <PreviewTopBannerBackgroundWrapper>
                    {isLogoActive && (
                        <img
                            src={logoSrc}
                            alt="logo"
                            className={classNames({
                                "usual-mode-logo__clz": !isPrm,
                                "prm-mode-logo__clz":
                                    isPrm &&
                                    kioskWays[navigationIndex].name !==
                                        "printerOptions",
                                "email-prm-mode-logo__clz":
                                    isPrm &&
                                    kioskWays[navigationIndex].name ===
                                        "printerOptions",
                            })}
                        />
                    )}
                </PreviewTopBannerBackgroundWrapper>
            )}
        </div>
    );
}
