import { CANCEL_BUTTON, VALIDATED_EXTENSION_FILE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import { kioskStore } from "@pages/Kiosk/store";

type BackButtonType = { onClose: () => void };

export function BackButton({ onClose }: BackButtonType): JSX.Element {
    const {
        project: {
            template: {
                content: { actionsButtons },
            },
        },
    } = useSnapshot(kioskStore);

    const backButton = getImageContentById(
        // eslint-disable-next-line
        ((actionsButtons as ActionsButtonsDesignType).items as any).find(
            (actionButton: ActionsButtonsDesignItemType) =>
                actionButton.name === CANCEL_BUTTON
        ).id
    );
    return (
        <div
            onClick={() => {
                onClose();
            }}
        >
            {VALIDATED_EXTENSION_FILE.includes(backButton.slice(-4)) ? (
                <img
                    src={backButton}
                    style={{
                        height: "65px",
                        width: "350px",
                        opacity: 1,
                    }}
                />
            ) : (
                <DynamicSvgComponent
                    nameSvg={backButton}
                    width={"350px"}
                    height={"65px"}
                    opacity={"1"}
                />
            )}
        </div>
    );
}
