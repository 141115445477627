import { CATALOGUE_MODE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

import { FinalMessageFooterWrapper } from "./FinalMessageFooterWrapper";
import { FinalMessageWrapper } from "./FinalMessageWrapper";
import { HeaderWrapper } from "./HeaderWrapper";
import { OrderNumber } from "./OrderNumber";
import { TicketNumberMessageFooter } from "./TicketNumberMessageFooter";

export function OrderNumberWrapper(): JSX.Element | null {
    const { isOrderInsertedSuccessfully, isTicketNumberActive } =
        useSnapshot(kioskStore);

    return isOrderInsertedSuccessfully ||
        process.env.REACT_APP_MODE === CATALOGUE_MODE ? (
        <React.Fragment>
            <HeaderWrapper />
            <FinalMessageWrapper />

            <div className="d-flex flex-column justify-content-center align-items-center">
                {isTicketNumberActive === true && (
                    <>
                        <TicketNumberMessageFooter />
                        <OrderNumber />
                    </>
                )}
            </div>

            <FinalMessageFooterWrapper />
        </React.Fragment>
    ) : null;
}
