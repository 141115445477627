import {
    BACK_BUTTON_ROLE,
    CATALOGUE_MODE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import {
    getAlertActionButtonImageSrc,
    getImageContentById,
} from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import { kioskStore as store } from "@pages/Kiosk/store";

import "./index.css";

type PropsType = {
    handleClearClickAndCollectInput: () => void;
    setIsQrCodeCorrectOrderNotProcessedModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setIsReScanQrCodeModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
    setQrCodeScanTryingNumber: React.Dispatch<React.SetStateAction<number>>;
    qrCodeScanTryingNumber: number;
    isReScanQrCodeModalOpened: boolean;
};
export function ReScanQrCodeModal({
    handleClearClickAndCollectInput,
    setIsReScanQrCodeModalOpened,
    isReScanQrCodeModalOpened,
    setIsQrCodeCorrectOrderNotProcessedModalOpened,
    setQrCodeScanTryingNumber,
    qrCodeScanTryingNumber,
}: PropsType): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content: { actionsButtons },
            },
        },
    } = useSnapshot(store);

    const alertImgSrc = getAlertActionButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );

    const backButton = (
        actionsButtons as ActionsButtonsDesignType
    ).items.filter((item) => {
        return item.role === BACK_BUTTON_ROLE;
    })[0];

    const backImageSrc = getImageContentById(backButton.id);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isReScanQrCodeModalOpened}
            onRequestClose={() => {
                setIsReScanQrCodeModalOpened(false);
                handleClearClickAndCollectInput();
            }}
            contentLabel="qr-code -incorrect"
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: "15px solid #040404",
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    overflow: "hidden",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "400px" }}
            >
                <img src={alertImgSrc} />
            </div>

            <div
                className="d-flex justify-content-center text-center w-100"
                style={{ height: "270px", fontSize: "55px" }}
            >
                {t("Click & Collect pickup")}
            </div>
            <div
                className="d-flex flex-column align-items-center text-center w-100"
                style={{ height: "400px", fontSize: "45px" }}
            >
                <span>
                    {t(
                        "A problem has occurred, please scan your QR code again."
                    )}
                </span>
            </div>
            <div
                className="d-flex justify-content-center align-items-center text-center w-100"
                style={{ height: "200px", fontSize: "35px" }}
            >
                {VALIDATED_EXTENSION_FILE.includes(backImageSrc.slice(-4)) ? (
                    <img
                        src={backImageSrc}
                        alt="backImageSrc"
                        style={{
                            height: isPrm ? "70" : "87px",
                            width: isPrm ? "281px" : "350px",
                        }}
                        onClick={() => {
                            handleClearClickAndCollectInput();
                            setIsReScanQrCodeModalOpened(false);
                            setIsQrCodeCorrectOrderNotProcessedModalOpened(
                                false
                            );
                            setQrCodeScanTryingNumber(
                                qrCodeScanTryingNumber + 1
                            );
                        }}
                    />
                ) : (
                    <DynamicSvgComponent
                        nameSvg={backImageSrc}
                        width={isPrm ? "281px" : "350px"}
                        height={isPrm ? "70" : "87px"}
                        onClick={() => {
                            handleClearClickAndCollectInput();
                            setIsReScanQrCodeModalOpened(false);
                            setIsQrCodeCorrectOrderNotProcessedModalOpened(
                                false
                            );
                            setQrCodeScanTryingNumber(
                                qrCodeScanTryingNumber + 1
                            );
                        }}
                    />
                )}
            </div>
        </Modal>
    );
}
