import { TCPOS } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import { getImageContentById } from "@helpers/general";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { PreviewTopBannerBackgroundWrapper } from "@components/common/PreviewTopBannerBackgroundWrapper";
import { UsualModeLanguagesWrapper } from "@components/common/UsualModeLanguageWrapper";
import { MainContentWrapper } from "@components/common/UsualModeMainContentWrapper";
import { ContainerWrapper } from "@components/common/UsualModeMainContentWrapper/ContainerWrapper";
import { InputKeyboardWrapper } from "@components/InputKeyboardWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export function UsualMode(): JSX.Element {
    const { posEditor } = useSnapshot(store);
    const {
        actionsButtonsItems,
        isHeaderActive,
        isActionButtonsActive,
        isInformationMessageActive,
        informationMessageItems,
        customerLanguage,
        isSendEmailConfirmed,
        isLogoActive,
        project: { template },
        isTopBannerActive,
    } = useSnapshot(kioskStore);

    const logoSrc = getImageContentById(
        (template.content.generalDesign as GeneralDesignType).logo.id as string
    );

    const activeActionsButtons = actionsButtonsItems?.filter((item) => {
        return item.active === true && item.name !== "printTicket";
    });

    const mainActiveActionsButtons = activeActionsButtons?.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <PreviewBackgroundWrapper
            selectedMode={
                isSendEmailConfirmed
                    ? "printerOptionsConfirmationEmail"
                    : undefined
            }
        >
            {
                <ContainerWrapper>
                    <>
                        <div className="d-flex justify-content-center align-items-center">
                            {isTopBannerActive && (
                                <PreviewTopBannerBackgroundWrapper
                                    selectedSubStep={
                                        isSendEmailConfirmed
                                            ? "printerOptionsConfirmationEmail"
                                            : undefined
                                    }
                                >
                                    {isLogoActive && (
                                        <img
                                            src={logoSrc}
                                            alt="logo"
                                            className={"usual-mode-logo__clz"}
                                        />
                                    )}
                                </PreviewTopBannerBackgroundWrapper>
                            )}
                        </div>

                        {isHeaderActive && isInformationMessageActive ? (
                            posEditor === TCPOS ? (
                                <h1
                                    style={{
                                        height: "100px",
                                        font: "600 55px / 60px Nunito Sans",
                                        textAlign: "center",
                                        maxWidth: "60rem",
                                        marginTop: "20%",
                                        marginInline: "auto",
                                        lineHeight: "6rem",
                                    }}
                                >
                                    {t(
                                        "Would you like to print your order ticket?"
                                    )}
                                </h1>
                            ) : (
                                <div
                                    className="d-flex justify-content-center align-items-center mx-3"
                                    style={{
                                        height: "100%",
                                    }}
                                >
                                    <span
                                        className="text-center two-lines-preview-text"
                                        style={{
                                            fontSize: "55px",
                                        }}
                                    >
                                        {
                                            informationMessageItems[
                                                customerLanguage.name
                                            ].content
                                        }
                                    </span>
                                </div>
                            )
                        ) : (
                            <div></div>
                        )}
                        {isSendEmailConfirmed ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <InputKeyboardWrapper />
                            </div>
                        ) : (
                            <MainContentWrapper />
                        )}

                        <ActionsButtonsWrapper
                            actionsButtonsItems={mainActiveActionsButtons}
                            actionButtonsActive={isActionButtonsActive}
                        />
                        <UsualModeLanguagesWrapper />
                        <ConfigOptionsWrapper />
                    </>
                </ContainerWrapper>
            }
        </PreviewBackgroundWrapper>
    );
}
