import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";

import { getProductTitle } from "@components/OrderTaking/Helpers";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export default function WorkflowTitle({ product, currentWorkflow }) {
    const { isPrm } = useSnapshot(kioskStore);
    const { globalcard } = useSelector((state) => state.orderSlice);
    const title = currentWorkflow
        ? getProductTitle(
              globalcard?.items?.[
                  currentWorkflow?.workflow[currentWorkflow.index]?.iuudproduct
              ]?.name
          )
        : "";

    return (
        <div
            className=" d-flex justify-content-center align-items-center workflow-title w-100"
            style={{ paddingLeft: isPrm ? "10rem" : "10rem" }}
        >
            <div className="d-flex flex-column align-items-center">
                <div
                    key={title + "1"}
                    className="workflow-product-title preview-text"
                >
                    {title}
                </div>

                {product?.selectedOptions?.length > 0 && (
                    <div className="info-options-product">
                        {product?.selectedOptions.join(",")}
                    </div>
                )}
            </div>
        </div>
    );
}

WorkflowTitle.propTypes = {
    product: PropTypes.string,
    currentWorkflow: PropTypes.any,
};
