/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    BACK_BUTTON_ROLE,
    CATALOGUE_MODE,
    VALIDATED_EXTENSION_FILE,
    VALINA_PAYMENT_DEFAULT_MODE,
} from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import {
    getGiveUpActionButtonImageSrc,
    getImageContentById,
    getPreviousSkippedStepNumber,
} from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { syncStore } from "@components/Synchronization/store";

import {
    kioskStore,
    setAmountDeposited,
    setCreditCardPaymentChoice,
    setIsAnotherPaymentModeButtonDisabled,
    setIsBackButtonDisabled,
    setIsBackWithoutCreditCardPaymentChoice,
    setIsCashTransactionValidated,
    setIsPaymentRefused,
    setIsPaymentTechnicalProblemDetected,
    setIsRegulationModeModalGiveUpButtonClicked,
    setIsRegulationModesModalButtonClicked,
    setIsTpeWaitingTimeReached,
    setIsTransactionCanceled,
    setLeftToPayAfterCashMachineValidation,
    setNavigationIndex,
    setPaymentModes,
} from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";
import { PrmMainContentWrapper } from "./PrmMainContentWrapper";

type PropsType = {
    containerRef: HTMLElement | null;
    setIsTpaValinaModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

export function RegulationModesModalWrapper({
    containerRef,
    setIsTpaValinaModalOpened,
}: PropsType): JSX.Element {
    const {
        isPrm,
        isRegulationModesModalOpened,
        creditCardPaymentChoice,
        project: {
            template: {
                content: { generalDesign, actionsButtons },
            },
        },
        isPaymentRefused,
        selectedRegulationModeValue,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        isRegulationModeModalButtonClicked,

        isRegulationModeModalGiveUpButtonClicked,
        paymentModes,
        cashMachineStatus,
    } = useSnapshot(kioskStore);
    const { peripheralShopApp } = useSnapshot(syncStore);

    const logoSrc = getImageContentById(
        (generalDesign as GeneralDesignType).logo.id as string
    );

    const backButton = (
        actionsButtons as ActionsButtonsDesignType
    ).items.filter((item) => {
        return item.role === BACK_BUTTON_ROLE;
    })[0];

    const backImageSrc = getImageContentById(backButton.id);

    async function handleBackButtonOnClickEvent() {
        if (creditCardPaymentChoice === "") {
            setIsBackWithoutCreditCardPaymentChoice(true);
        }
        setIsPaymentRefused(false);
        setIsBackButtonDisabled(false);
        setIsAnotherPaymentModeButtonDisabled(true);
        setIsPaymentTechnicalProblemDetected(false);
        setIsCashTransactionValidated(false);
        setLeftToPayAfterCashMachineValidation(0);
        setAmountDeposited(0);
        setIsPaymentRefused(false);
        setIsTransactionCanceled(false);

        const localPaymentModes = paymentModes.filter(
            (mode) => mode.paymentMode === "loyalty"
        );

        setPaymentModes(localPaymentModes);

        if (isRegulationModeModalGiveUpButtonClicked) {
            setNavigationIndex(0);
        } else {
            if (selectedRegulationModeValue === "creditCard") {
                const monetics: any = peripheralShopApp?.monetics;
                const moneticsConfig: any = monetics
                    ? Object.values(monetics)
                    : [];
                const payment_method = moneticsConfig[0]?.payment_method;
                const mark = moneticsConfig[0]?.mark;

                if (
                    mark === "Valina" &&
                    payment_method.value === VALINA_PAYMENT_DEFAULT_MODE
                ) {
                    setCreditCardPaymentChoice("");
                    setIsBackWithoutCreditCardPaymentChoice(true);
                }
            }

            setNavigationIndex(
                navigationIndex -
                    Number(
                        await getPreviousSkippedStepNumber(
                            kioskWays,
                            navigationIndex,
                            isAuthenticationAccepted,
                            isUserAuthenticated,
                            selectedSaleModeValue,
                            selectedInformationMode,
                            isOrderPaidWithLoyaltyAccountBalance,
                            selectedRegulationModeValue
                        )
                    )
            );
        }
    }

    React.useEffect(() => {
        if (isRegulationModeModalButtonClicked) {
            handleBackButtonOnClickEvent();
        }
    }, [isRegulationModeModalButtonClicked]);
    React.useEffect(() => {
        setIsTpeWaitingTimeReached(true);
        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            setIsTpeWaitingTimeReached(false);
        };
    }, []);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={
                isRegulationModesModalOpened &&
                cashMachineStatus.isBillRefund === false
            }
            // eslint-disable-next-line
            contentRef={() => containerRef!}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: isPrm ? "end" : "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: isPrm ? "1334px" : "1742px",
                    width: isPrm ? "860px" : "944px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div className="d-flex justify-content-center align-items-center w-100">
                <img
                    src={logoSrc}
                    alt="logo"
                    style={{ height: "230px", width: "230px" }}
                />
            </div>
            <div
                className="d-flex justify-content-center align-items-center px-5 text-center"
                style={{ height: "370px" }}
            >
                {isPaymentRefused ? (
                    <div>
                        {selectedRegulationModeValue === "changeMachine" ? (
                            <span
                                style={{
                                    font: "normal normal 600 50px/80px Segoe UI",
                                }}
                            >
                                {t(
                                    "The payment with the change machine is refused."
                                )}
                            </span>
                        ) : (
                            <span
                                style={{
                                    font: "normal normal 600 50px/80px Segoe UI",
                                }}
                            >
                                {t(
                                    "The payment with the credit card is refused."
                                )}
                            </span>
                        )}{" "}
                        <span
                            style={{
                                font: "normal normal 600 50px/80px Segoe UI",
                            }}
                        >
                            {t("Please choose another payment method.")}
                        </span>
                    </div>
                ) : (
                    <span
                        style={{ font: "normal normal 600 55px/80px Segoe UI" }}
                    >
                        {t("Choose another payment method.")}
                    </span>
                )}
            </div>

            <div
                className="d-flex justify-content-center w-100"
                style={{
                    height: isPrm ? "450px" : "900px",
                    alignItems: isPrm ? "end" : "center",
                }}
            >
                {isPrm ? (
                    <PrmMainContentWrapper
                        setIsTpaValinaModalOpened={setIsTpaValinaModalOpened}
                    />
                ) : (
                    <MainContentWrapper
                        setIsTpaValinaModalOpened={setIsTpaValinaModalOpened}
                    />
                )}
            </div>

            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "200px", gap: "10px" }}
            >
                {VALIDATED_EXTENSION_FILE.includes(backImageSrc.slice(-4)) ? (
                    <img
                        src={backImageSrc}
                        alt="backImageSrc"
                        style={{
                            height: isPrm ? "70px" : "87px",
                            width: isPrm ? "281px" : "350px",
                            pointerEvents: isRegulationModeModalButtonClicked
                                ? "none"
                                : "auto",
                            opacity: isRegulationModeModalButtonClicked
                                ? "0.5"
                                : 1,
                        }}
                        onClick={() =>
                            setIsRegulationModesModalButtonClicked(true)
                        }
                    />
                ) : (
                    <DynamicSvgComponent
                        nameSvg={backImageSrc}
                        height={isPrm ? "70px" : "87px"}
                        width={isPrm ? "281px" : "350px"}
                        pointerEvents={
                            isRegulationModeModalButtonClicked ? "none" : "auto"
                        }
                        opacity={
                            isRegulationModeModalButtonClicked ? "0.5" : "1"
                        }
                        onClick={() =>
                            setIsRegulationModesModalButtonClicked(true)
                        }
                    />
                )}

                {VALIDATED_EXTENSION_FILE.includes(
                    getGiveUpActionButtonImageSrc(
                        actionsButtons as ActionsButtonsDesignType
                    ).slice(-4)
                ) ? (
                    <img
                        src={getGiveUpActionButtonImageSrc(
                            actionsButtons as ActionsButtonsDesignType
                        )}
                        style={{
                            height: isPrm ? "70px" : "87px",
                            width: isPrm ? "281px" : "350px",
                            pointerEvents: isRegulationModeModalButtonClicked
                                ? "none"
                                : "auto",
                            opacity: isRegulationModeModalButtonClicked
                                ? "0.5"
                                : 1,
                        }}
                        onClick={() => {
                            setIsRegulationModesModalButtonClicked(true);
                            setIsRegulationModeModalGiveUpButtonClicked(true);
                        }}
                    />
                ) : (
                    <DynamicSvgComponent
                        nameSvg={getGiveUpActionButtonImageSrc(
                            actionsButtons as ActionsButtonsDesignType
                        )}
                        height={isPrm ? "70px" : "87px"}
                        width={isPrm ? "281px" : "350px"}
                        pointerEvents={
                            isRegulationModeModalButtonClicked ? "none" : "auto"
                        }
                        opacity={
                            isRegulationModeModalButtonClicked ? "0.5" : "1"
                        }
                        onClick={() => {
                            setIsRegulationModesModalButtonClicked(true);
                            setIsRegulationModeModalGiveUpButtonClicked(true);
                        }}
                    />
                )}
            </div>
        </Modal>
    );
}
