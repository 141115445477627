/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    AGENT_LOCAL_API_URL,
    CASH_MACHINE_NETWORK_ERROR,
    CASH_MACHINE_OCCUPIED,
    CASHDRO_INSERT_AMOUNT,
    CASHDRO_TRANSACTION_ID,
    CHANGEMACHINE_LIVE_CHECK,
    CHANGEMACHINE_PAYEMENT_WITHOUT_RETURN_OR_CREDITCARD_PAYMENT,
    CHANGEMACHINE_PAYMENT_DEPOSITE,
    CHANGEMACHINE_PAYMENT_DISPENSE,
    CHANGEMACHINE_PAYMENT_NOTE_REMOVED,
    CHANGEMACHINE_PAYMENT_WAIT_REMOVE_NOTE,
    CHANGEMACHINE_PAYMENT_WITH_REFUND_ANOMALY,
    CHANGEMACHINE_PAYMENT_WITH_RETURNED_AMOUNT,
    PAYMENT_ABORTED,
    PAYMENT_REFUSED,
    PAYMENT_TIMEOUT,
    TCPOS,
    VALIDE_PAYMENT_WITH_CHANGEMACHINE,
} from "@constants";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { t } from "i18next";
import { cloneDeep } from "lodash";
import { snapshot, useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";

import { store } from "@store";

import {
    getPreviousSkippedStepNumber,
    getSkippedStepNumber,
    middleWareOrderTrace,
} from "@helpers/general";

import { AbortTransactionModalWrapper } from "@components/AbortTransactionModalWrapper";
import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import CachMashineModalWrapper from "@components/common/CashMachineModal";
import { HeaderWrapper } from "@components/common/HeaderWrapper";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PaymentContainerWrapper } from "@components/common/Payment/PaymentContainer";
import { PaymentMainContentWrapper } from "@components/common/Payment/PaymentMainContentWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { RegulationModesModalWrapper } from "@components/common/RegulationModesModalWrapper";
import { validateTCPOSCommand } from "@components/OrderTaking/Helpers";
import { syncStore } from "@components/Synchronization/store";
import { TpaValinaModalWrapper } from "@components/TpaValinaModalWrapper";
import { TpeAvailablityModal } from "@components/TpeAvailablityModal";

import {
    kioskStore,
    setActionsButtonsItems,
    setAmountDeposited,
    setChosenTransition,
    setInformationMessageItems,
    setIsAbortTransactionModalOpened,
    setIsAcceptAbortCBTransactionButtonDisabled,
    setIsActionButtonsActive,
    setIsAnotherPaymentModeButtonDisabled,
    setIsBackButtonDisabled,
    setIsButtonClickedOnCBPaymentMode,
    setIsHeaderActive,
    setIsInformationMessageActive,
    setIsLogoActive,
    setIsPaymentRefused,
    setIsPaymentTechnicalProblemDetected,
    setIsRegulationModesModalOpened,
    setIsTopBannerActive,
    setIsTpaValinaActionButtonsActive,
    setIsTpaValinaIconActive,
    setIsTpaValinaInformationMessageActive,
    setIsTpeNotAvailable,
    setIsTransactionCanceled,
    setLeftToPayAfterCashMachineValidation,
    setMainContentItems,
    setNavigationIndex,
    setPaymentModes,
    setRegulationModesNumber,
    setRenderAmount,
    setTotalAmountDeposited,
    setTpaValinaActionButtons,
    setTpaValinaIconId,
    setTpaValinaInformationMessageItems,
    setVoucherAmount,
} from "@pages/Kiosk/store";
import {
    setCashDroTransactionId,
    setCashMachineStatus,
} from "@pages/Kiosk/store/actions";

export function PaymentStep(): JSX.Element {
    console.log(`🚀 ~ PaymentStep ~ PaymentStep: ${new Date().getTime()}`);
    const controllerRef = React.useRef<AbortController>();
    const { transitions, posEditor } = useSnapshot(store);
    const {
        isActionButtonsActive,
        customerLanguage,
        actionsButtonsItems,
        isRegulationModesModalOpened,
        selectedRegulationModeValue,
        regulationModesNumber,
        project: {
            template: {
                content,
                pages: { ways },
            },
        },
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        isBackWithoutCreditCardPaymentChoice,
        isAbortTransactionModalOpened,
        regulationModesModalKey,
        leftToPayAfterCashMachineValidation,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        paymentModes,
        cashUuID,
        creditCardUuId,
        isTransactionCanceled,
        totalAmountDeposited,
        isPaymentRefused,
        isCashMachineTransactionCanceled,
        isTpeNotAvailable,
        isButtonClickedOnCBPaymentMode,
        creditCardPaymentChoice,
        orderTotalPrice,
        cashMachineStatus,
    } = useSnapshot(kioskStore);

    const { peripheralShopApp, machineIpAddress, selectedShopApp } =
        useSnapshot(syncStore);

    const order = useSelector((state: any) => state.orderSlice.order);

    const isTpaValinaShowModal = (
        content.payment as PaymentContentItemType
    ).items.filter((item) => item.name === "credit card")[0]?.languages[
        customerLanguage.name
    ].paymentInstructions.TPAValina?.active as boolean;

    const monetics: any = peripheralShopApp?.monetics;
    const moneticsConfig: any = monetics ? Object.values(monetics) : [];
    const mark = moneticsConfig[0]?.mark;
    const setup_type = moneticsConfig[0]?.setup_type;

    const isvalinaOpen =
        isTpaValinaShowModal &&
        mark === "Valina" &&
        creditCardPaymentChoice === "" &&
        selectedRegulationModeValue === "creditCard" &&
        !isRegulationModesModalOpened &&
        !(TCPOS === posEditor);

    const [isTpaValinaModalOpened, setIsTpaValinaModalOpened] =
        React.useState<boolean>(isvalinaOpen);

    const [isPaymentTimeout, setIsPaymentTimeout] =
        React.useState<boolean>(false);

    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(
        null
    );

    const activeActionsButtons = actionsButtonsItems.filter((item) => {
        if (regulationModesNumber === 1) {
            return item.name !== "otherPaymentMethod" && item.active === true;
        } else {
            return item.active === true;
        }
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    async function handleNavigationAfterTransactionCancelling() {
        const { isButtonClickedOnCBPaymentMode } = snapshot(kioskStore);
        console.log(
            "If the transaction is canceled, it means that the payment request has been refused.",
            isButtonClickedOnCBPaymentMode.isAnotherPaymentModeButtonClicked
        );
        if (
            selectedRegulationModeValue === "creditCard" &&
            Object.keys((peripheralShopApp?.monetics as any) || {}).length > 0
        ) {
            const { mark } = Object.values(
                peripheralShopApp?.monetics as any
            )[0] as any;

            if (
                !isButtonClickedOnCBPaymentMode.isAnotherPaymentModeButtonClicked &&
                !isButtonClickedOnCBPaymentMode.isBackPaymentModeButtonClicked &&
                mark === "Nepting"
            ) {
                console.log("Time out has been reach");
                setIsTpeNotAvailable(true);
                setIsPaymentTimeout(true);

                return;
            }
        }

        console.log(
            "isButtonClickedOnCBPaymentMode",
            isButtonClickedOnCBPaymentMode
        );

        if (isButtonClickedOnCBPaymentMode.isAnotherPaymentModeButtonClicked) {
            console.log(
                "Successfully open regulation mode after transaction aborted"
            );
            setIsRegulationModesModalOpened(true);
            setIsButtonClickedOnCBPaymentMode({
                isAnotherPaymentModeButtonClicked: false,
                isBackPaymentModeButtonClicked: false,
            });
            return;
        }

        setIsButtonClickedOnCBPaymentMode({
            isAnotherPaymentModeButtonClicked: false,
            isBackPaymentModeButtonClicked: false,
        });
        setIsRegulationModesModalOpened(false);
        setNavigationIndex(
            navigationIndex -
                Number(
                    await getPreviousSkippedStepNumber(
                        kioskWays,
                        navigationIndex,
                        isAuthenticationAccepted,
                        isUserAuthenticated,
                        selectedSaleModeValue,
                        selectedInformationMode,
                        isOrderPaidWithLoyaltyAccountBalance,
                        selectedRegulationModeValue
                    )
                )
        );
        setIsPaymentRefused(false);
        setIsTransactionCanceled(false);
        setAmountDeposited(0);
    }

    async function handleCommandPayment() {
        const timestamp = new Date().getTime();
        let url = "";

        let bodyPaymentData: any = {};
        const middlewareApiUrl = JSON.parse(
            localStorage.getItem("middlewareApiUrl") as string
        );

        const localTotalPrice = isOrderPartialPaidWithLoyaltyAccountBalance
            ? totalOrderAfterPaidWithLoyaltyAccountBalance
            : orderTotalPrice;

        const unitPrice =
            leftToPayAfterCashMachineValidation !== 0
                ? leftToPayAfterCashMachineValidation
                : localTotalPrice;
        console.log(
            `Totale Command Payment: ${JSON.stringify({
                isOrderPartialPaidWithLoyaltyAccountBalance,
                totalOrderAfterPaidWithLoyaltyAccountBalance,
                orderTotalPrice,
                leftToPayAfterCashMachineValidation,
                localTotalPrice,
            })}`
        );
        if (selectedRegulationModeValue === "changeMachine") {
            console.log(`PaymentWithCashMachine unit price: ${unitPrice}`);
            setIsBackButtonDisabled(false);
            const cashMachine: any = peripheralShopApp?.cash_managements;
            const cashMachineConfig: any = cashMachine
                ? Object.values(cashMachine)
                : [];
            const cashMachineIpAddress = cashMachineConfig[0]?.ip;
            const cashMachineApiUrl = cashMachineConfig[0]?.cashMachineApiUrl;
            const cashMachineMark = cashMachineConfig[0]?.mark;

            if (cashMachineMark === "Cashdro") {
                const userCashDro = cashMachineConfig[0]?.user;
                const passCashDro = cashMachineConfig[0]?.password;
                const userPos = "USER_KIOSCK_AUTO";
                const posId = cashMachineConfig[0]?.pos_id;

                url = `http://${cashMachineApiUrl}:5011/changeOperationCashdro?timestamp=${timestamp}`;
                bodyPaymentData = {
                    cashMachineIpAddress,
                    clientIP: machineIpAddress,
                    amount: unitPrice,
                    userCashDro,
                    passCashDro,
                    userPos,
                    typeOperation: CASHDRO_INSERT_AMOUNT,
                    posId,
                };
            } else {
                url = `http://${cashMachineApiUrl}:5011/changeOperation?timestamp=${timestamp}`;
                bodyPaymentData = {
                    cashMachineIpAddress,
                    clientIP: machineIpAddress,
                    amount: unitPrice,
                    userId: selectedShopApp.name,
                    userPwd: selectedShopApp.passcode,
                };
            }

            console.log(
                "payment with change machine api launched is",
                JSON.stringify(url)
            );
        } else {
            console.log(
                "Credit card payment choice on payment step",
                JSON.stringify({ creditCardPaymentChoice })
            );

            const monetics: any = peripheralShopApp?.monetics;
            const moneticsConfig: any = monetics ? Object.values(monetics) : [];
            const mark = moneticsConfig[0]?.mark;
            const timeout = moneticsConfig[0]?.timeout;
            const speed = moneticsConfig[0]?.speed;
            const port = moneticsConfig[0]?.port;
            const kiss = moneticsConfig[0]?.kiss;

            const printers: any = Object.values(
                (peripheralShopApp?.printers as any) ?? {}
            );
            const printerIp = printers[0]?.ip ?? "";
            const printerPath = printers[0]?.path ?? "";

            console.log(
                `Payment with CB unit price: ${unitPrice} and monetic config : ${mark}`
            );

            if (mark === "Valina") {
                //! Disable for now ! Don't change it until reach @maher
                // const ValinaMode =
                //     creditCardPaymentChoice.length === 0
                //         ? payment_method.value
                //         : creditCardPaymentChoice === "withCode"
                //         ? VALINA_PAYMENT_WITH_CONTACT
                //         : VALINA_PAYMENT_WITHOUT_CONTACT;

                url = `${AGENT_LOCAL_API_URL}/ReglementValinaV1?timestamp=${timestamp}`;
                bodyPaymentData = {
                    Printerconf:
                        printerIp.length === 0 ? printerPath : printerIp,
                    originName: new URL(middlewareApiUrl).hostname,
                    commande: middleWareOrderTrace(order.orderItems),
                    data: {
                        Type: "Debit",
                        Id_POS: machineIpAddress,
                        Montant: unitPrice,
                        ParamTPA: {
                            Port: port,
                            Vitesse: speed,
                            Timeout: timeout,
                            Contact: 1,
                            Kiss: kiss,
                        },
                    },
                };
            } else {
                url = `${middlewareApiUrl}/ReglementCommandeV1`;
                bodyPaymentData = {
                    unitaire: unitPrice,
                    commande: middleWareOrderTrace(order.orderItems),
                };
            }
            console.log("payment with CB api launched is", JSON.stringify(url));
        }

        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        console.log(`handleCommandPayment initial call  with url ${url}`);

        controllerRef.current = new AbortController();
        const signal = controllerRef.current.signal;

        await fetchEventSource(url, {
            signal,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyPaymentData),
            async onopen(response) {
                console.log(
                    "Respone on open payment transaction:",
                    JSON.stringify(response)
                );
                if (response.ok && response.status === 200) {
                    return; // everything's good
                } else if (
                    response.status >= 400 &&
                    response.status < 500 &&
                    response.status !== 429
                ) {
                    throw new Error(`error status ${response.status}`);
                } else {
                    console.log(
                        "Respone on open payment transaction: on throw last error"
                    );
                    throw Error();
                }
            },
            async onmessage(event) {
                console.log(
                    JSON.stringify({ event: event.event, data: event.data })
                );
                switch (event.event) {
                    case "result": {
                        const code = JSON.parse(event.data).code;

                        setIsBackButtonDisabled(false);
                        switch (code) {
                            //code returned after payment validation with the change machine
                            case VALIDE_PAYMENT_WITH_CHANGEMACHINE: {
                                console.log(
                                    `The cash machine transaction is validated, i receive the code 1001`
                                );
                                setLeftToPayAfterCashMachineValidation(
                                    Number(JSON.parse(event.data).response[1])
                                );

                                const localDepositedAmount =
                                    Number(JSON.parse(event.data).response[0]) -
                                    Number(JSON.parse(event.data).response[1]);
                                const localTotalAmountDeposited =
                                    totalAmountDeposited + localDepositedAmount;
                                setTotalAmountDeposited(
                                    localTotalAmountDeposited
                                );

                                const selectedPaymentMode = cloneDeep(
                                    paymentModes.find(
                                        (mode) =>
                                            mode.paymentMode ===
                                            selectedRegulationModeValue
                                    )
                                );

                                if (localDepositedAmount !== 0) {
                                    if (selectedPaymentMode !== undefined) {
                                        const selectedPaymentModeIndex =
                                            paymentModes.findIndex(
                                                (mode) =>
                                                    mode.paymentMode ===
                                                    selectedRegulationModeValue
                                            );
                                        const localPaymentModes = cloneDeep(
                                            paymentModes.map(
                                                (paymentMode) => paymentMode
                                            )
                                        );
                                        console.log({
                                            selectedPaymentModeIndex,
                                        });
                                        console.log(
                                            JSON.stringify({
                                                localPaymentModes,
                                                ccc: localPaymentModes[
                                                    selectedPaymentModeIndex
                                                ],
                                            })
                                        );
                                        localPaymentModes[
                                            selectedPaymentModeIndex
                                        ] = {
                                            paymentModeUuiD: cashUuID,
                                            paymentMode:
                                                selectedRegulationModeValue,
                                            paymentAmount:
                                                selectedPaymentMode.paymentAmount +
                                                parseFloat(
                                                    localDepositedAmount.toFixed(
                                                        2
                                                    )
                                                ),
                                            data: {
                                                renderAmount: 0,
                                            },
                                            totalAmountDeposited:
                                                Number(
                                                    selectedPaymentMode.totalAmountDeposited
                                                ) +
                                                Number(
                                                    parseFloat(
                                                        localDepositedAmount.toFixed(
                                                            2
                                                        )
                                                    )
                                                ),
                                        };
                                        console.log(
                                            "JSON.stringify(localPaymentModes) in PaymentStep code 1001 If",
                                            JSON.stringify(localPaymentModes)
                                        );
                                        setPaymentModes(localPaymentModes);
                                    } else {
                                        console.log(
                                            "JSON.stringify(localPaymentModes) in PaymentStep code 1001 else",
                                            JSON.stringify([
                                                ...paymentModes,
                                                {
                                                    paymentModeUuiD: cashUuID,
                                                    paymentMode:
                                                        selectedRegulationModeValue,
                                                    paymentAmount: Number(
                                                        parseFloat(
                                                            localDepositedAmount.toFixed(
                                                                2
                                                            )
                                                        )
                                                    ),
                                                    data: {
                                                        renderAmount: 0,
                                                    },
                                                    totalAmountDeposited:
                                                        parseFloat(
                                                            localDepositedAmount.toFixed(
                                                                2
                                                            )
                                                        ),
                                                },
                                            ])
                                        );
                                        setPaymentModes([
                                            ...paymentModes,
                                            {
                                                paymentModeUuiD: cashUuID,
                                                paymentMode:
                                                    selectedRegulationModeValue,
                                                paymentAmount: Number(
                                                    parseFloat(
                                                        localDepositedAmount.toFixed(
                                                            2
                                                        )
                                                    )
                                                ),
                                                data: {
                                                    renderAmount: 0,
                                                },
                                                totalAmountDeposited: Number(
                                                    parseFloat(
                                                        localDepositedAmount.toFixed(
                                                            2
                                                        )
                                                    )
                                                ),
                                            },
                                        ]);
                                    }
                                }

                                controllerRef?.current?.abort();
                                break;
                            }
                            //code returned after payment with change machine without a render amount or payment with credit card
                            case CHANGEMACHINE_PAYEMENT_WITHOUT_RETURN_OR_CREDITCARD_PAYMENT: {
                                const selectedPaymentMode = cloneDeep(
                                    paymentModes.find(
                                        (mode) =>
                                            mode.paymentMode ===
                                            selectedRegulationModeValue
                                    )
                                );
                                if (selectedPaymentMode !== undefined) {
                                    const selectedPaymentModeIndex =
                                        paymentModes.findIndex(
                                            (mode) =>
                                                mode.paymentMode ===
                                                selectedRegulationModeValue
                                        );

                                    const localPaymentModes = cloneDeep(
                                        paymentModes.map((paymentMode) => {
                                            return paymentMode;
                                        })
                                    );
                                    localPaymentModes[
                                        selectedPaymentModeIndex
                                    ] = {
                                        paymentModeUuiD: cashUuID,
                                        paymentMode:
                                            selectedRegulationModeValue,
                                        paymentAmount:
                                            Number(
                                                selectedPaymentMode.paymentAmount
                                            ) +
                                            Number(
                                                leftToPayAfterCashMachineValidation
                                            ),
                                        data: {
                                            renderAmount: 0,
                                        },
                                        totalAmountDeposited:
                                            Number(
                                                selectedPaymentMode.paymentAmount
                                            ) +
                                            Number(
                                                leftToPayAfterCashMachineValidation
                                            ),
                                    };
                                    console.log(
                                        "JSON.stringify(localPaymentModes) in PaymentStep code 1002 IF",
                                        JSON.stringify(localPaymentModes)
                                    );
                                    setPaymentModes(localPaymentModes);
                                } else {
                                    console.log(
                                        "JSON.stringify(PaymentModes) in PaymentStep code 1002 else",
                                        JSON.stringify([
                                            ...paymentModes,
                                            {
                                                paymentModeUuiD:
                                                    selectedRegulationModeValue ===
                                                    "changeMachine"
                                                        ? cashUuID
                                                        : creditCardUuId,
                                                paymentMode:
                                                    selectedRegulationModeValue,
                                                paymentAmount: Number(
                                                    leftToPayAfterCashMachineValidation !==
                                                        0
                                                        ? leftToPayAfterCashMachineValidation
                                                        : isOrderPartialPaidWithLoyaltyAccountBalance
                                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                        : orderTotalPrice
                                                ),
                                                data: {
                                                    renderAmount: 0,
                                                },
                                                totalAmountDeposited: Number(
                                                    leftToPayAfterCashMachineValidation !==
                                                        0
                                                        ? leftToPayAfterCashMachineValidation
                                                        : isOrderPartialPaidWithLoyaltyAccountBalance
                                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                        : orderTotalPrice
                                                ),
                                            },
                                        ])
                                    );
                                    setPaymentModes([
                                        ...paymentModes,
                                        {
                                            paymentModeUuiD:
                                                selectedRegulationModeValue ===
                                                "changeMachine"
                                                    ? cashUuID
                                                    : creditCardUuId,
                                            paymentMode:
                                                selectedRegulationModeValue,
                                            paymentAmount: Number(
                                                leftToPayAfterCashMachineValidation !==
                                                    0
                                                    ? leftToPayAfterCashMachineValidation
                                                    : isOrderPartialPaidWithLoyaltyAccountBalance
                                                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                    : orderTotalPrice
                                            ),
                                            data: {
                                                renderAmount: 0,
                                            },
                                            totalAmountDeposited: Number(
                                                leftToPayAfterCashMachineValidation !==
                                                    0
                                                    ? leftToPayAfterCashMachineValidation
                                                    : isOrderPartialPaidWithLoyaltyAccountBalance
                                                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                    : orderTotalPrice
                                            ),
                                        },
                                    ]);
                                }
                                setNavigationIndex(
                                    navigationIndex +
                                        Number(
                                            await getSkippedStepNumber(
                                                kioskWays,
                                                navigationIndex,
                                                isAuthenticationAccepted,
                                                isUserAuthenticated,
                                                selectedSaleModeValue,
                                                selectedInformationMode,
                                                isOrderPaidWithLoyaltyAccountBalance,
                                                selectedRegulationModeValue,
                                                content,
                                                Number(orderTotalPrice)
                                            )
                                        )
                                );
                                controllerRef?.current?.abort();
                                break;
                            }
                            //code returned after payment with the change machine with an amount returned
                            case CHANGEMACHINE_PAYMENT_WITH_RETURNED_AMOUNT: {
                                const depositedAmount = Number(
                                    JSON.parse(event.data).response
                                        .depositedAmount
                                );
                                const returnedAmount = Number(
                                    JSON.parse(event.data).response
                                        .returnedAmount
                                );

                                console.log(
                                    `Payment with change machine (code 1003) with an amount returned:${returnedAmount} and closing the change machine render modal`
                                );
                                setRenderAmount(returnedAmount);
                                setAmountDeposited(depositedAmount);
                                setIsAnotherPaymentModeButtonDisabled(true);
                                setIsBackButtonDisabled(true);
                                const selectedPaymentMode = cloneDeep(
                                    paymentModes.find(
                                        (mode) =>
                                            mode.paymentMode ===
                                            selectedRegulationModeValue
                                    )
                                );

                                if (selectedPaymentMode !== undefined) {
                                    const selectedPaymentModeIndex =
                                        paymentModes.findIndex(
                                            (mode) =>
                                                mode.paymentMode ===
                                                selectedRegulationModeValue
                                        );

                                    const localPaymentModes = cloneDeep(
                                        paymentModes.map((paymentMode) => {
                                            return paymentMode;
                                        })
                                    );
                                    localPaymentModes[
                                        selectedPaymentModeIndex
                                    ] = {
                                        paymentModeUuiD: cashUuID,
                                        paymentMode:
                                            selectedRegulationModeValue,
                                        paymentAmount:
                                            Number(
                                                selectedPaymentMode.paymentAmount
                                            ) +
                                            leftToPayAfterCashMachineValidation,
                                        data: {
                                            renderAmount: returnedAmount,
                                        },
                                        totalAmountDeposited:
                                            Number(
                                                selectedPaymentMode.paymentAmount
                                            ) +
                                            Number(
                                                leftToPayAfterCashMachineValidation
                                            ) +
                                            returnedAmount,
                                    };
                                    console.log(
                                        "JSON.stringify(localPaymentModes) in PaymentStep code 1003 IF",
                                        JSON.stringify(localPaymentModes)
                                    );
                                    setPaymentModes(localPaymentModes);
                                } else {
                                    console.log(
                                        "JSON.stringify(PaymentModes) in PaymentStep code 1003 else",
                                        JSON.stringify([
                                            ...paymentModes,
                                            {
                                                paymentModeUuiD: cashUuID,
                                                paymentMode:
                                                    selectedRegulationModeValue,
                                                paymentAmount:
                                                    isOrderPartialPaidWithLoyaltyAccountBalance
                                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                        : orderTotalPrice,
                                                data: {
                                                    renderAmount:
                                                        returnedAmount,
                                                },
                                                totalAmountDeposited:
                                                    Number(
                                                        isOrderPartialPaidWithLoyaltyAccountBalance
                                                            ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                            : orderTotalPrice
                                                    ) + returnedAmount,
                                            },
                                        ])
                                    );
                                    setPaymentModes([
                                        ...paymentModes,
                                        {
                                            paymentModeUuiD: cashUuID,
                                            paymentMode:
                                                selectedRegulationModeValue,
                                            paymentAmount: Number(
                                                isOrderPartialPaidWithLoyaltyAccountBalance
                                                    ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                    : orderTotalPrice
                                            ),
                                            data: {
                                                renderAmount: returnedAmount,
                                            },
                                            totalAmountDeposited:
                                                Number(
                                                    isOrderPartialPaidWithLoyaltyAccountBalance
                                                        ? totalOrderAfterPaidWithLoyaltyAccountBalance
                                                        : orderTotalPrice
                                                ) + returnedAmount,
                                        },
                                    ]);
                                }

                                setNavigationIndex(
                                    navigationIndex +
                                        Number(
                                            await getSkippedStepNumber(
                                                kioskWays,
                                                navigationIndex,
                                                isAuthenticationAccepted,
                                                isUserAuthenticated,
                                                selectedSaleModeValue,
                                                selectedInformationMode,
                                                isOrderPaidWithLoyaltyAccountBalance,
                                                selectedRegulationModeValue,
                                                content,
                                                Number(orderTotalPrice)
                                            )
                                        )
                                );
                                controllerRef?.current?.abort();
                                break;
                            }
                            //code returned after payment with the change machine in the case of a refund anomaly
                            case CHANGEMACHINE_PAYMENT_WITH_REFUND_ANOMALY: {
                                const depositedAmount = Number(
                                    JSON.parse(event.data).response
                                        .depositedAmount
                                );

                                console.log(
                                    `Payment with the change machine in the case of a refund anomaly (code 999) with a voucher amount:${parseFloat(
                                        depositedAmount.toFixed(2)
                                    )} and opening the refund anomaly modal`
                                );
                                setVoucherAmount(
                                    parseFloat(depositedAmount.toFixed(2))
                                );
                                setAmountDeposited(depositedAmount);
                                setIsAnotherPaymentModeButtonDisabled(true);
                                setIsBackButtonDisabled(true);
                                setCashMachineStatus({
                                    isNotAvailable: false,
                                    isBillRefund: false,
                                    isRefundProblem: false,
                                    isOccupied: false,
                                    isNetworkProblem: false,
                                    isNetworkLost: false,
                                    isOpened: true,
                                    isRefund: true,
                                });
                                controllerRef?.current?.abort();
                                break;
                            }
                            //code returned after payment is refused
                            case PAYMENT_REFUSED: {
                                console.log("The payment is refused");
                                setIsButtonClickedOnCBPaymentMode({
                                    isAnotherPaymentModeButtonClicked: false,
                                    isBackPaymentModeButtonClicked: false,
                                });
                                if (
                                    selectedRegulationModeValue ===
                                    "changeMachine"
                                ) {
                                    console.log(
                                        "Cash Machine payment error or refused"
                                    );
                                    setCashMachineStatus({
                                        isBillRefund: false,
                                        isRefundProblem: false,
                                        isRefund: false,
                                        isOccupied: false,
                                        isNetworkLost: false,
                                        isNetworkProblem: false,
                                        isOpened: true,
                                        isNotAvailable: true,
                                    });
                                    return;
                                }

                                setIsPaymentRefused(true);
                                // !todo
                                console.log(
                                    JSON.stringify({
                                        isPaymentRefused: isPaymentRefused,
                                        isTransactionCanceled:
                                            isTransactionCanceled,
                                        totalAmountDeposited:
                                            totalAmountDeposited,
                                        isCashMachineTransactionCanceled:
                                            isCashMachineTransactionCanceled,
                                    })
                                );

                                console.log(
                                    "After the payment was refused, another payment options modal opened.",
                                    JSON.stringify({
                                        selectedRegulationModeValue,
                                        isButtonClickedOnCBPaymentMode,
                                    })
                                );

                                if (
                                    selectedRegulationModeValue === "creditCard"
                                ) {
                                    if (
                                        isButtonClickedOnCBPaymentMode.isAnotherPaymentModeButtonClicked
                                    ) {
                                        setIsRegulationModesModalOpened(true);
                                    } else {
                                        setIsTpeNotAvailable(true);
                                    }
                                } else {
                                    setIsRegulationModesModalOpened(true);
                                }

                                controllerRef?.current?.abort();
                                break;
                            }
                            case PAYMENT_ABORTED: {
                                console.log(
                                    "PAYMENT_ABORTED event with code",
                                    PAYMENT_ABORTED
                                );
                                setIsAbortTransactionModalOpened(false);

                                const localPaymentModes = paymentModes.filter(
                                    (mode) => mode.paymentMode === "loyalty"
                                );

                                setPaymentModes(localPaymentModes);

                                setLeftToPayAfterCashMachineValidation(0);

                                handleNavigationAfterTransactionCancelling();
                                setIsTransactionCanceled(true);

                                controllerRef?.current?.abort();
                                break;
                            }
                            case PAYMENT_TIMEOUT: {
                                setIsTpeNotAvailable(true);
                                setIsPaymentTimeout(true);
                                controllerRef?.current?.abort();
                                break;
                            }
                            case CASH_MACHINE_OCCUPIED: {
                                console.log("CASH machine occupied");
                                setCashMachineStatus({
                                    isNotAvailable: false,
                                    isBillRefund: false,
                                    isRefund: false,
                                    isRefundProblem: false,
                                    isNetworkLost: false,
                                    isNetworkProblem: false,
                                    isOpened: true,
                                    isOccupied: true,
                                });
                                controllerRef?.current?.abort();
                                break;
                            }
                            case CASH_MACHINE_NETWORK_ERROR: {
                                console.log("CASH machine network error");
                                setCashMachineStatus({
                                    isNotAvailable: false,
                                    isBillRefund: false,
                                    isRefund: false,
                                    isRefundProblem: false,
                                    isNetworkLost: false,
                                    isOccupied: false,
                                    isNetworkProblem: true,
                                    isOpened: true,
                                });
                                controllerRef?.current?.abort();
                                break;
                            }
                        }

                        break;
                    }
                    case "Bloc": {
                        setIsAnotherPaymentModeButtonDisabled(true);
                        setIsBackButtonDisabled(true);
                        console.log(
                            "I receive the event bloc it means that the change machine is on counting and the buttons wil be disabled"
                        );
                        break;
                    }
                    case "Menu": {
                        setIsAnotherPaymentModeButtonDisabled(false);
                        setIsBackButtonDisabled(false);
                        setIsButtonClickedOnCBPaymentMode({
                            isAnotherPaymentModeButtonClicked: false,
                            isBackPaymentModeButtonClicked: false,
                        });
                        break;
                    }
                    case "usermessage": {
                        //Todo : response for moneyer
                        if (selectedRegulationModeValue === "changeMachine") {
                            setIsAnotherPaymentModeButtonDisabled(false);
                            setIsBackButtonDisabled(false);
                            const eventMessage = JSON.parse(event.data).message;
                            switch (eventMessage.code) {
                                case CHANGEMACHINE_PAYMENT_DEPOSITE:
                                    console.log(
                                        "deposited amount event detected with amount: ",
                                        eventMessage.depositedAmount
                                    );
                                    if (eventMessage.depositedAmount === 0) {
                                        return;
                                    }
                                    if (
                                        eventMessage.depositedAmount >=
                                        unitPrice
                                    ) {
                                        setIsAnotherPaymentModeButtonDisabled(
                                            true
                                        );
                                        setIsBackButtonDisabled(true);
                                        setIsButtonClickedOnCBPaymentMode({
                                            isAnotherPaymentModeButtonClicked:
                                                true,
                                            isBackPaymentModeButtonClicked:
                                                true,
                                        });
                                    }

                                    setAmountDeposited(
                                        (eventMessage.depositedAmount as number) ??
                                            0
                                    );
                                    break;
                                case CHANGEMACHINE_PAYMENT_DISPENSE:
                                    console.log(
                                        "dispensed amount event detected with amount: ",
                                        eventMessage.dispensedAmount
                                    );
                                    if (eventMessage.dispensedAmount === 0) {
                                        return;
                                    }
                                    setRenderAmount(
                                        eventMessage.dispensedAmount
                                    );
                                    break;
                                case CHANGEMACHINE_PAYMENT_WAIT_REMOVE_NOTE:
                                    setCashMachineStatus({
                                        isNotAvailable: false,
                                        isRefundProblem: false,
                                        isRefund: false,
                                        isOccupied: false,
                                        isNetworkLost: false,
                                        isNetworkProblem: false,
                                        isOpened: true,
                                        isBillRefund: true,
                                    });
                                    console.log(
                                        "waiting to remove notes to open modal"
                                    );

                                    break;
                                case CHANGEMACHINE_PAYMENT_NOTE_REMOVED:
                                    setRenderAmount(0);
                                    setCashMachineStatus({
                                        isNotAvailable: false,
                                        isRefundProblem: false,
                                        isRefund: false,
                                        isOccupied: false,
                                        isNetworkLost: false,
                                        isOpened: false,
                                        isBillRefund: false,
                                        isNetworkProblem: false,
                                    });

                                    console.log(
                                        "removed notes detected to close modal"
                                    );
                                    break;
                                case CHANGEMACHINE_LIVE_CHECK:
                                    console.log(
                                        `is cash machine a live: ${eventMessage.isAlive}`
                                    );
                                    setCashMachineStatus({
                                        isNotAvailable: false,
                                        isRefundProblem: false,
                                        isRefund: false,
                                        isOccupied: false,
                                        isNetworkProblem: false,
                                        isBillRefund: false,
                                        isOpened: !eventMessage.isAlive,
                                        isNetworkLost: !eventMessage.isAlive,
                                    });
                                    break;
                                case CASHDRO_TRANSACTION_ID:
                                    console.log(
                                        `Cashdro Transaction ID: ${eventMessage.idtransaction}`
                                    );
                                    setCashDroTransactionId(
                                        eventMessage.idtransaction
                                    );
                                    break;
                                default:
                                    console.log(
                                        "unxpected code on user message for cash machine with code: ",
                                        eventMessage
                                    );
                                    break;
                            }
                        } else {
                            //Todo : response for Tpe:valina or nepting
                            if (mark === "Valina") {
                                if (
                                    JSON.parse(event.data).message.includes(
                                        "IDLE"
                                    )
                                ) {
                                    if (!isTransactionCanceled) {
                                        setIsAnotherPaymentModeButtonDisabled(
                                            false
                                        );
                                        setIsBackButtonDisabled(false);
                                    }
                                } else if (
                                    JSON.parse(event.data).message.includes(
                                        "REMOVE_CARD"
                                    )
                                ) {
                                    setIsAcceptAbortCBTransactionButtonDisabled(
                                        true
                                    );
                                    setIsAnotherPaymentModeButtonDisabled(true);
                                    setIsBackButtonDisabled(true);
                                }
                            } else {
                                if (
                                    JSON.parse(event.data).message.includes(
                                        "INSEREZ CARTE"
                                    ) ||
                                    JSON.parse(event.data).message.includes(
                                        "PAIEMENT CRÉÉ"
                                    )
                                ) {
                                    if (
                                        !isButtonClickedOnCBPaymentMode.isAnotherPaymentModeButtonClicked ||
                                        !isButtonClickedOnCBPaymentMode.isBackPaymentModeButtonClicked
                                    ) {
                                        setIsTransactionCanceled(false);
                                        setIsAnotherPaymentModeButtonDisabled(
                                            false
                                        );
                                        setIsBackButtonDisabled(false);
                                    }
                                } else if (
                                    JSON.parse(event.data).message.includes(
                                        "PAIEMENT ACCEPTE"
                                    )
                                ) {
                                    console.log("The payment is accepted");
                                    setIsAcceptAbortCBTransactionButtonDisabled(
                                        true
                                    );
                                    setIsAnotherPaymentModeButtonDisabled(true);
                                    setIsBackButtonDisabled(true);
                                }
                            }
                        }
                    }
                }
            },
            onclose() {
                console.log("connection close in payment API");
                controllerRef?.current?.abort();
            },
            onerror(error) {
                console.log(
                    `Error during the payment, with the message: ${error}`
                );
                setIsAnotherPaymentModeButtonDisabled(false);
                setIsBackButtonDisabled(false);
                if (selectedRegulationModeValue === "changeMachine") {
                    console.log("Cash Machine payment error or refused");
                    setCashMachineStatus({
                        isBillRefund: false,
                        isRefund: false,
                        isRefundProblem: false,
                        isOccupied: false,
                        isNetworkLost: false,
                        isNetworkProblem: false,
                        isOpened: true,
                        isNotAvailable: true,
                    });
                    controllerRef?.current?.abort();
                    throw error;
                }
                setIsTpeNotAvailable(true);
                setIsPaymentTechnicalProblemDetected(true);
                controllerRef?.current?.abort();
                throw error;
            },
        });
    }

    React.useEffect(() => {
        if (
            !["atTheCounter", "BDP"].includes(selectedRegulationModeValue) &&
            selectedRegulationModeValue !== undefined &&
            selectedRegulationModeValue !== ""
        ) {
            setIsLogoActive(
                (ways.payment as PaymentPageType).topBanner.logo.active
            );

            setIsTopBannerActive(
                (ways.payment as PaymentPageType).topBanner.active
            );
            setIsHeaderActive(
                (ways.payment as PaymentPageType).regulationMode[
                    selectedRegulationModeValue
                ].header.active
            );

            setIsInformationMessageActive(
                (ways.payment as PaymentPageType).regulationMode[
                    selectedRegulationModeValue
                ].header.informationMessage.active
            );

            setInformationMessageItems(
                (ways.payment as PaymentPageType).regulationMode[
                    selectedRegulationModeValue
                ].header.informationMessage.languages
            );

            setIsActionButtonsActive(
                (ways.payment as PaymentPageType).regulationMode[
                    selectedRegulationModeValue
                ].actionsButtons.active
            );

            setActionsButtonsItems(
                (
                    (ways.payment as PaymentPageType).regulationMode[
                        selectedRegulationModeValue
                    ].actionsButtons as ActionsButtonsDesignType
                ).items
            );

            setMainContentItems(
                (content[ways.payment?.name] as PaymentContentItemType).items
            );

            setIsTpaValinaIconActive(
                // eslint-disable-next-line
                (
                    (content.TPAValina as TPAValinaPageType)
                        .header as NewHeaderType
                ).icon!.active
            );
            setTpaValinaIconId(
                // eslint-disable-next-line
                (
                    (content.TPAValina as TPAValinaPageType)
                        .header as NewHeaderType
                ).icon!.id
            );
            setIsTpaValinaInformationMessageActive(
                (content.TPAValina as TPAValinaPageType).header
                    .informationMessage.active
            );
            setTpaValinaInformationMessageItems(
                (content.TPAValina as TPAValinaPageType).header
                    .informationMessage.languages
            );
            setIsTpaValinaActionButtonsActive(
                (content.TPAValina as TPAValinaPageType).actionsButtons.active
            );
            setTpaValinaActionButtons(
                (content.TPAValina as TPAValinaPageType).actionsButtons.items
            );
            setChosenTransition(
                transitions[(ways.payment as PaymentPageType).animation]
            );
        }
        setRegulationModesNumber(
            (content.meansOfPayment as ProjectContentItemType).items.filter(
                (item) => {
                    return item.active === true;
                }
            ).length
        );
    }, [regulationModesModalKey]);

    React.useEffect(() => {
        if (TCPOS === posEditor) {
            validateTCPOSCommand();
        } else if (isRegulationModesModalOpened === false && !isvalinaOpen) {
            console.log("🚀 ~ React.useEffect ~ handleCommandPayment:");
            handleCommandPayment();
        }
    }, [
        selectedRegulationModeValue,
        regulationModesModalKey,
        isBackWithoutCreditCardPaymentChoice,
        creditCardPaymentChoice,
    ]);

    React.useEffect(() => {
        if (!(TCPOS === posEditor)) {
            setIsBackButtonDisabled(true);
        }
        return () => {
            setIsBackButtonDisabled(false);
        };
    }, []);
    return (
        <PreviewBackgroundWrapper>
            <PaymentContainerWrapper ref={(ref) => setContainerRef(ref)}>
                {containerRef ? (
                    isAbortTransactionModalOpened === true ? (
                        <AbortTransactionModalWrapper
                            containerRef={containerRef}
                        />
                    ) : null
                ) : null}

                {containerRef ? (
                    isRegulationModesModalOpened === true ? (
                        <RegulationModesModalWrapper
                            containerRef={containerRef}
                            setIsTpaValinaModalOpened={
                                setIsTpaValinaModalOpened
                            }
                        />
                    ) : null
                ) : null}

                {isTpaValinaModalOpened ? (
                    <TpaValinaModalWrapper
                        isTpaValinaModalOpened={isTpaValinaModalOpened}
                        setIsTpaValinaModalOpened={setIsTpaValinaModalOpened}
                    />
                ) : null}

                {isTpeNotAvailable === true ? (
                    <TpeAvailablityModal
                        isPaymentTimeout={isPaymentTimeout}
                        setIsPaymentTimeout={setIsPaymentTimeout}
                    />
                ) : null}
                {cashMachineStatus.isOpened ? (
                    <CachMashineModalWrapper
                        handleCommandPayment={handleCommandPayment}
                    />
                ) : null}

                <LogoWrapper />
                <HeaderWrapper />
                <PaymentMainContentWrapper />

                {(mark !== undefined &&
                    selectedRegulationModeValue === "creditCard" &&
                    mark === "Nepting" &&
                    setup_type.value === "ERT 45") ||
                setup_type.value === "ERT 46" ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "10%",
                        }}
                    >
                        <h1
                            style={{
                                textAlign: "center",
                                maxWidth: "90%",
                                marginInline: "auto",
                            }}
                        >
                            {t(
                                "Please proceed from your terminal to cancel your transaction."
                            )}
                        </h1>
                    </div>
                ) : (
                    !(TCPOS === posEditor) && (
                        <ActionsButtonsWrapper
                            actionsButtonsItems={mainActiveActionsButtons}
                            actionButtonsActive={isActionButtonsActive}
                        />
                    )
                )}
            </PaymentContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
