import { TCPOS } from "@constants";
import classNames from "classnames";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import { getImageContentById } from "@helpers/general";

import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { PreviewTopBannerBackgroundWrapper } from "@components/common/PreviewTopBannerBackgroundWrapper";
import { PrmActionsButtonsWrapper } from "@components/common/PrmActionsButtonsWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { PrmLanguagesWrapper } from "@components/common/PrmLanguageWrapper";
import { MainContentWrapper } from "@components/common/PrmModeMainContentWrapper";
import { GlobalPrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/GlobalPrmModeWrapper";
import { PrmModeSubStepContainerWrapper } from "@components/common/PrmModeSubStepContainerWrapper";
import { InputKeyboardWrapper } from "@components/InputKeyboardWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export function PrmMode(): JSX.Element {
    const {
        isHeaderActive,
        actionsButtonsItems,
        isActionButtonsActive,
        isInformationMessageActive,
        informationMessageItems,
        customerLanguage,
        isSendEmailConfirmed,
        isLogoActive,
        isPrm,
        project: { template },
        navigationIndex,
        kioskWays,
        isTopBannerActive,
    } = useSnapshot(kioskStore);

    const { posEditor } = useSnapshot(store);
    const logoSrc = getImageContentById(
        (template.content.generalDesign as GeneralDesignType).logo.id as string
    );
    const activeActionsButtons = actionsButtonsItems?.filter((item) => {
        return item.active === true && item.name !== "printTicket";
    });

    const mainActiveActionsButtons = activeActionsButtons?.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <GlobalPrmModeWrapper>
            <PrmBackgroundWrapper />
            <div>
                <PreviewBackgroundWrapper
                    selectedMode={
                        isSendEmailConfirmed
                            ? "printerOptionsConfirmationEmail"
                            : undefined
                    }
                >
                    <PrmModeSubStepContainerWrapper>
                        <div className="d-flex justify-content-center align-items-center">
                            {isTopBannerActive && (
                                <PreviewTopBannerBackgroundWrapper
                                    selectedSubStep={
                                        isSendEmailConfirmed
                                            ? "printerOptionsConfirmationEmail"
                                            : undefined
                                    }
                                >
                                    {isLogoActive && (
                                        <img
                                            src={logoSrc}
                                            alt="logo"
                                            className={classNames({
                                                "prm-mode-logo__clz":
                                                    isPrm &&
                                                    kioskWays[navigationIndex]
                                                        .name !==
                                                        "printerOptions",
                                                "email-prm-mode-logo__clz":
                                                    isPrm &&
                                                    kioskWays[navigationIndex]
                                                        .name ===
                                                        "printerOptions",
                                            })}
                                        />
                                    )}
                                </PreviewTopBannerBackgroundWrapper>
                            )}
                        </div>

                        {isHeaderActive ? (
                            TCPOS === posEditor ? (
                                <h1
                                    style={{
                                        height: "100px",
                                        font: "600 55px / 60px Nunito Sans",
                                        textAlign: "center",
                                        maxWidth: "60rem",
                                        marginInline: "auto",
                                        lineHeight: "6rem",
                                    }}
                                >
                                    {t(
                                        "Would you like to print your order ticket?"
                                    )}
                                </h1>
                            ) : isInformationMessageActive ? (
                                <div className="d-flex justify-content-center align-items-center mx-3">
                                    <span
                                        style={{
                                            fontSize: "55px",
                                            textAlign: "center",
                                        }}
                                    >
                                        {
                                            informationMessageItems[
                                                customerLanguage.name
                                            ].content
                                        }
                                    </span>
                                </div>
                            ) : null
                        ) : (
                            <div></div>
                        )}

                        {isSendEmailConfirmed ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <InputKeyboardWrapper />
                            </div>
                        ) : (
                            <MainContentWrapper />
                        )}

                        <PrmActionsButtonsWrapper
                            actionsButtonsItems={mainActiveActionsButtons}
                            actionButtonsActive={isActionButtonsActive}
                        />

                        <div className="d-flex align-items-center justify-content-end">
                            <PrmLanguagesWrapper />
                            <ConfigOptionsWrapper />
                        </div>
                    </PrmModeSubStepContainerWrapper>
                </PreviewBackgroundWrapper>
            </div>
        </GlobalPrmModeWrapper>
    );
}
