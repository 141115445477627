import { changeLanguage } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next";
import {
    ButtonDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

import { getImageContentById } from "@helpers/general";

import { setCustomerLanguage, kioskStore as store } from "@pages/Kiosk/store";

import "./index.css";

type PrmLanguagesWrapperType = {
    isScanningQrCode?: boolean;
};
export function PrmLanguagesWrapper({
    isScanningQrCode,
}: PrmLanguagesWrapperType): JSX.Element {
    const { t } = useTranslation();
    const {
        activeLanguages,
        customerLanguage,

        isLanguagesActive,
    } = useSnapshot(store);

    const [isDropDownOpened, setIsDropDownOpened] =
        React.useState<boolean>(false);
    const dropdownItems = activeLanguages.filter(
        (item) => item.name !== customerLanguage.name
    );

    const customerLanguageImage = getImageContentById(customerLanguage.id);
    return (
        <React.Fragment>
            {isLanguagesActive &&
            (!isScanningQrCode || isScanningQrCode === undefined) ? (
                <ButtonDropdown
                    direction="up"
                    isOpen={isDropDownOpened}
                    toggle={() => setIsDropDownOpened(!isDropDownOpened)}
                    className="align-items-center"
                    style={{ minWidth: "230px" }}
                >
                    <DropdownToggle
                        className="d-flex align-items-center"
                        caret
                        style={{
                            height: "65px",
                            gap: "20px",
                            borderColor: "#F8F8F6",
                            backgroundColor: "#F8F8F6",
                            color: " #000000",
                            minWidth: "230px",
                        }}
                    >
                        <img
                            src={customerLanguageImage}
                            alt={customerLanguage.title}
                            style={{
                                height: "35px",
                                width: "50px",
                            }}
                        />
                        <span
                            className="customer-language-title__clz"
                            style={{
                                fontSize: "30px",
                            }}
                        >
                            {t(customerLanguage.title)}
                        </span>
                    </DropdownToggle>
                    <DropdownMenu
                        style={{
                            minWidth: "245px",
                            marginTop: "20px",
                        }}
                    >
                        {dropdownItems.map((item, index) => {
                            const imgSrc = getImageContentById(item.id);
                            return (
                                <DropdownItem
                                    className="d-flex"
                                    style={{
                                        gap: "20px",
                                        height: "65px",
                                        padding: "0 10px 0 10px ",
                                    }}
                                    key={index}
                                    onClick={() => {
                                        setCustomerLanguage(item);
                                        changeLanguage(item.name);
                                    }}
                                >
                                    <img
                                        src={imgSrc}
                                        alt={item.title}
                                        style={{
                                            height: "35px",
                                            width: "50px",
                                            marginTop: "8px",
                                        }}
                                    />
                                    <span
                                        style={{
                                            fontSize: "30px",
                                        }}
                                    >
                                        {t(item.title)}
                                    </span>
                                </DropdownItem>
                            );
                        })}
                    </DropdownMenu>
                </ButtonDropdown>
            ) : (
                <div></div>
            )}
        </React.Fragment>
    );
}
