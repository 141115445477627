import styled from "styled-components";

export const ContainerWrapper = styled.div`
    display: grid;
    background-color: transparent;
    position: relative;
    height: 100%;
    grid-template-rows: min-content min-content 1fr min-content;
    gap: 8%;
`;
