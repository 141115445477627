import {
    DESIGN_IS_NEXT_BUTTON_ROLE,
    DESIGN_NO_THANKS_BUTTON_ROLE,
    NO_ThANKS_CARD,
} from "@constants";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "reactstrap";

import { getImageContentById } from "@helpers/general";

import {
    goToPreviousWorkflow,
    SelectProduct,
    setNextWorkflowStep,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature/";

import { kioskStore } from "@pages/Kiosk/store";

export default function NoThanks({ productType, product, workflowData }) {
    const dispatch = useDispatch();
    const { customerLanguage, isPrm } = useSnapshot(kioskStore);

    if (workflowData?.length === 0) return null;

    const btnRole =
        productType === NO_ThANKS_CARD
            ? DESIGN_NO_THANKS_BUTTON_ROLE
            : DESIGN_IS_NEXT_BUTTON_ROLE;

    const isProductSelected =
        workflowData[workflowData.length - 1].workflow[
            workflowData[workflowData.length - 1].index
        ].NbrOfChoices > 0;

    const goToNextStep = () => {
        if (
            workflowData[workflowData.length - 1].step >
            workflowData[workflowData.length - 1].index
        ) {
            dispatch(setNextWorkflowStep());
        } else if (workflowData.length > 1) {
            dispatch(goToPreviousWorkflow());
        } else {
            dispatch(setWorkflowData([]));
            dispatch(SelectProduct({}));
        }
    };

    const { designItems } = useSelector((state) => state.settingSlice);

    const imageButton = designItems.filter((item) => item.role === btnRole);
    const defaultImage = productType === NO_ThANKS_CARD ? `NO THANKS` : `Next`;

    const ImageSrc =
        imageButton.length > 0
            ? getImageContentById(imageButton[0].id)
            : defaultImage;
    const heightNoThanks = !isPrm ? product.heightOfImage + 100 + "px" : "100%";

    const widthtNoThanks = !isPrm ? product.widthOfImage + "px" : "100%";

    return (
        <Card
            className="d-flex flex-column"
            style={{
                height: "100%",
                borderRadius: "15px",
                maxHeight: heightNoThanks,
                minWidth: widthtNoThanks,
                visibility:
                    isProductSelected && productType === NO_ThANKS_CARD
                        ? "hidden"
                        : "visible",
            }}
            onClick={goToNextStep}
        >
            <div
                className="first-box"
                style={{
                    width: "100%",
                    flex: 80,
                    height: "auto",
                    position: "relative",
                    minHeight: heightNoThanks,
                    maxHeight: heightNoThanks,
                    minWidth: widthtNoThanks,
                }}
            >
                {["webp", ".png", ".jpg"].includes(ImageSrc.slice(-4)) ? (
                    <img
                        className="w-100 h-100"
                        alt={ImageSrc}
                        src={ImageSrc}
                        loading="lazy"
                    />
                ) : (
                    <div className="d-flex flex-column justify-content-center  align-items-center w-100">
                        {isPrm && (
                            <img
                                className={"product-image"}
                                height={isPrm ? "min-content" : "100%"}
                                src="./images/blanc.jpg"
                                style={{
                                    width: "100%",
                                    objectFit: "contain",
                                    borderTopRightRadius: "15px",
                                    borderTopLeftRadius: "15px",
                                    objectPosition: "top",
                                    height: "100%",
                                    minWidth: "100% !important",
                                    minHeight: "100% !important",
                                }}
                            />
                        )}
                        <div
                            className="d-flex justify-content-center align-items-center h-100 w-100 product-title__clz"
                            style={{
                                fontSize: " 1.5vw",
                                position: "absolute",
                                paddingTop: "6vh",
                            }}
                        >
                            {t(defaultImage, {
                                lng: customerLanguage.name,
                            }).toUpperCase()}
                        </div>
                    </div>
                )}
            </div>
            {!["webp", ".png", ".jpg"].includes(ImageSrc.slice(-4)) && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: isPrm ? "0" : "unset",

                        height: "100px",
                        borderRadius: "15px",

                        gap: "",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                ></div>
            )}
        </Card>
    );
}

NoThanks.propTypes = {
    productType: PropTypes.string.isRequired,
    product: PropTypes.object,
    workflowData: PropTypes.array,
};
