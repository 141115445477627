import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function HeaderStep(): JSX.Element {
    const {
        customerLanguage,
        isHeaderActive,
        isUserAccountActive,
        isUserAuthenticated,
        userAccountMessageItems,
        user,
        isInformationMessageActive,
        informationMessageItems,
    } = useSnapshot(kioskStore);
    return (
        <div className="d-flex  justify-content-center align-items-center w-100">
            {isHeaderActive && (
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    {isUserAccountActive ? (
                        isUserAuthenticated ? (
                            <div
                                className="d-flex justify-content-center align-items-center  w-50"
                                style={{ gap: "20px" }}
                            >
                                <img
                                    src="./images/man-head-header.png"
                                    alt="header"
                                    style={{
                                        height: "35px",
                                        width: "35px",
                                    }}
                                />
                                <span
                                    className="mb-0 two-lines-preview-text text-center"
                                    style={{ fontSize: "35px" }}
                                >
                                    {`${
                                        userAccountMessageItems[
                                            customerLanguage.name
                                        ].content
                                    } ${
                                        user.firstName === ""
                                            ? user.lastName
                                            : user.firstName
                                    }`}
                                </span>
                            </div>
                        ) : null
                    ) : null}

                    {isInformationMessageActive ? (
                        <span
                            className="two-lines-preview-text w-100"
                            style={{
                                fontSize: "55px",
                                textAlign: "center",
                            }}
                        >
                            {
                                informationMessageItems[customerLanguage.name]
                                    .content
                            }
                        </span>
                    ) : null}
                </div>
            )}
        </div>
    );
}
