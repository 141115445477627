import { useSnapshot } from "valtio";

import React from "react";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import { kioskStore } from "@pages/Kiosk/store";

import { InformationMessageWrapper } from "./InformationMessageWrapper";
import { UserInfoWrapper } from "./UserInfoWrapper";

export function HeaderWrapper(): JSX.Element | null {
    const {
        isHeaderActive,
        isUserAuthenticated,
        project: {
            template: {
                pages: {
                    ways: { finalMessage },
                },
            },
        },
    } = useSnapshot(kioskStore);

    return (
        <div className="mt-4 justify-content-center align-items-center">
            {isHeaderActive === true && (
                <>
                    {(finalMessage as PageType).header.userAccount?.active ===
                        true &&
                        (isUserAuthenticated === true ? (
                            <FlexboxGrid
                                alignItemsCentered={true}
                                justifyContentCentered={true}
                                gap="20px"
                            >
                                <img
                                    src="./images/man-head-header.png"
                                    alt="header"
                                    style={{
                                        height: "35px",
                                        width: "35px",
                                    }}
                                />
                                <UserInfoWrapper />
                            </FlexboxGrid>
                        ) : null)}

                    <InformationMessageWrapper />
                </>
            )}
        </div>
    );
}
