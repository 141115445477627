import { VALIDATE_BUTTON_ROLE, VALIDATED_EXTENSION_FILE } from "@constants";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

type AuthenticatedUserListType = {
    isUserListModelOpen: boolean;
    setIsUserListModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedUser: React.Dispatch<React.SetStateAction<string | null>>;
    selectedUser: string | null;
    handleUserSelectionValidation: () => void;
    // eslint-disable-next-line
    keyboard: React.MutableRefObject<any>;
};

export default function AuthenticatedUserList({
    isUserListModelOpen,
    selectedUser,
    setSelectedUser,
    handleUserSelectionValidation,
    setIsUserListModelOpen,
    keyboard,
}: AuthenticatedUserListType): JSX.Element {
    const usersToken = JSON.parse(localStorage.getItem("usersToken") as string);
    const usersList = Object.entries(usersToken);
    // Global Container CSS (MODAL)
    const ModalContainerWrapper = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 2,
        },
        content: {
            width: "32rem",
            height: "48rem",
            inset: "28%",
            padding: "15px",
        },
    };

    const {
        project: {
            template: { content },
        },
    } = useSnapshot(kioskStore);
    // eslint-disable-next-line
    const validateButton = (content.actionsButtons as any).items.filter(
        // eslint-disable-next-line
        (item: any) => {
            return item.role === VALIDATE_BUTTON_ROLE;
        }
    )[0];
    const validateImageSrc = getImageContentById(validateButton.id);

    const handleUserSelection = (email: string) => {
        setSelectedUser(email === selectedUser ? null : email);
        keyboard.current.setInput(email, "email");
    };

    const closeModal = () => {
        setIsUserListModelOpen(false);
    };

    return (
        <Modal
            isOpen={isUserListModelOpen}
            style={ModalContainerWrapper}
            onRequestClose={closeModal}
        >
            <div className="user-list-modal ">
                {usersList.map(
                    // eslint-disable-next-line
                    ([email, token]: [string, any], index: number) => {
                        return (
                            <UserListCard
                                key={index}
                                firstName={token.firstName as string}
                                email={email}
                                isSelected={email === selectedUser}
                                onSelect={() => handleUserSelection(email)}
                            />
                        );
                    }
                )}
            </div>
            <div
                className="centered-items w-100 position-absolute user-list-validate-btn"
                onClick={handleUserSelectionValidation}
            >
                {validateImageSrc !== "" ? (
                    <div className="additional-sale-button mx-3">
                        {VALIDATED_EXTENSION_FILE.includes(
                            validateImageSrc.slice(-4)
                        ) ? (
                            <img
                                alt="validate button"
                                src={validateImageSrc}
                                className="user-list-validate-btn-img"
                            />
                        ) : (
                            <DynamicSvgComponent
                                nameSvg={validateImageSrc}
                                width={"350px"}
                                height={"87px"}
                            />
                        )}
                    </div>
                ) : (
                    <button className="additional-sale-button user-list-tag-btn">
                        <div className="text-button">{t("Validate")}</div>
                    </button>
                )}
            </div>
        </Modal>
    );
}

type UserListCardType = {
    firstName: string;
    email: string;
    isSelected: boolean;
    onSelect: () => void;
};

function UserListCard({
    firstName,
    email,
    isSelected,
    onSelect,
}: UserListCardType): JSX.Element {
    return (
        <div
            className={`d-flex bg-label-primary p-2 border rounded m-2 ${
                isSelected ? "border-success" : ""
            }`}
            onClick={onSelect}
        >
            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                <div className="me-2 pl-2 d-flex align-items-center">
                    <div style={{ color: "#636578" }} className="mb-1 mt-1">
                        <AccountCircleIcon />
                    </div>
                    <div className="d-flex flex-column ml-2">
                        <h3 className="ml-4 mb-1 mt-1">{firstName}</h3>
                        <p style={{ color: "#9d9d9d85" }}>{email}</p>
                    </div>
                </div>
                {isSelected ? (
                    <div>
                        <CheckCircleIcon fontSize="medium" color="success" />
                    </div>
                ) : null}
            </div>
        </div>
    );
}
