import { StyledButton } from "@aureskonnect/react-ui";
import { TCPOS } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next";

import { store } from "@store";

import {
    getSkippedStepNumber,
    VerifyIsPrinterStepWithPrintTicketButton,
} from "@helpers/general";

import {
    kioskStore,
    setIsPrintTicket,
    setIsSendMailWithPdfActive,
    setNavigationIndex,
} from "@pages/Kiosk/store";

import { ActionsButtonsImageContent } from "./ActionsButtonsImageContent";

type ActionsButtonsPropsType = {
    actionsButtonsItems: ActionsButtonsDesignItemType[];
    actionButtonsActive: boolean;
};

export function ActionsButtonsWrapper({
    actionsButtonsItems,
    actionButtonsActive,
}: ActionsButtonsPropsType): JSX.Element {
    const { t } = useTranslation();
    const { posEditor } = useSnapshot(store);
    const {
        navigationIndex,
        kioskWays,
        isAuthenticationAccepted,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        isUserAuthenticated,
        project: {
            template: { content },
        },
        customerLanguage,
        orderTotalPrice,
    } = useSnapshot(kioskStore);

    const isPrinterStep = VerifyIsPrinterStepWithPrintTicketButton();
    async function handleOnClickEvent() {
        setIsPrintTicket(true);
        setIsSendMailWithPdfActive(false);
        setNavigationIndex(
            navigationIndex +
                Number(
                    await getSkippedStepNumber(
                        kioskWays,
                        navigationIndex,
                        isAuthenticationAccepted,
                        isUserAuthenticated,
                        selectedSaleModeValue,
                        selectedInformationMode,
                        isOrderPaidWithLoyaltyAccountBalance,
                        selectedRegulationModeValue,
                        content,
                        Number(orderTotalPrice)
                    )
                )
        );
    }
    return (
        <div
            className="mx-3 d-flex justify-content-center align-items-center"
            style={{ gap: "40px", flexWrap: "wrap", marginBottom: "1vh" }}
        >
            {actionButtonsActive && (
                <>
                    {actionsButtonsItems.map(
                        (item: ActionsButtonsDesignItemType) => {
                            return (
                                <ActionsButtonsImageContent
                                    key={item.id}
                                    item={item}
                                />
                            );
                        }
                    )}

                    {isPrinterStep && !(TCPOS === posEditor) ? (
                        <StyledButton
                            rounded={true}
                            style={{
                                height: "80px",
                                width: "350px",
                                background: "#545454",
                                font: "normal normal 600 18px/24px Segoe UI",
                                boxShadow: "0px 3px 6px #00000029",
                                border: "1px solid #FFFFFF",
                                marginRight: "0px",
                            }}
                            onClick={handleOnClickEvent}
                        >
                            {t("Print ticket", { lng: customerLanguage.name })}
                        </StyledButton>
                    ) : null}
                </>
            )}
        </div>
    );
}
