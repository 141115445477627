/* eslint-disable @typescript-eslint/no-explicit-any */
import { VALIDATED_EXTENSION_FILE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { getColor } from "@components/OrderTaking/Helpers";

import { kioskStore } from "@pages/Kiosk/store";

type ImageBackgroundType = {
    PayButton: any;
    imageUrl: string;
    opacityStyle: string | undefined;
    DisplayedOrderPrice: string;
    setImgColor: React.Dispatch<React.SetStateAction<string>>;
    isSummery?: boolean;
    handleBasketActionsButtonsOnClickEvent: (button: any) => void;
    width: string;
    isDialogCartOpend?: boolean;
};

export function PaymentActionButton({
    PayButton,
    imageUrl,
    opacityStyle,
    DisplayedOrderPrice,
    handleBasketActionsButtonsOnClickEvent,
    isSummery,
    width,
    isDialogCartOpend,
}: ImageBackgroundType): JSX.Element {
    const [imgColor, setImgColor] = React.useState("#141414");

    const { isPrm } = useSnapshot(kioskStore);
    const isCustomButton = VALIDATED_EXTENSION_FILE.includes(
        imageUrl.slice(-4)
    );

    React.useEffect(() => {
        const image = new Image();
        image.crossOrigin = "Anonymous";
        image.src = imageUrl;

        image.onload = () => {
            const imgHeigth = image.height ?? 1;
            const imgWidth = image.width ?? 1;

            const pixelColor = getColor(image, imgHeigth, imgWidth);

            // Extract RGB values from the pixel data
            if (pixelColor) {
                const red = pixelColor[0];
                const green = pixelColor[1];
                const blue = pixelColor[2];

                const luminance =
                    (0.299 * red + 0.587 * green + 0.114 * blue) / 255;
                setImgColor(luminance > 0.5 ? "#141414" : "#ffffff");
            }
        };
    }, [imageUrl]);

    return (
        <div
            className="total-order-footer"
            onClick={() => {
                handleBasketActionsButtonsOnClickEvent(PayButton);
            }}
        >
            {isCustomButton ? (
                <img
                    alt="payment button"
                    src={imageUrl}
                    style={{
                        opacity: opacityStyle,
                        width: width,
                        height: "65px",
                    }}
                />
            ) : (
                <DynamicSvgComponent
                    nameSvg={imageUrl}
                    opacity={opacityStyle}
                    width={width}
                    height={"65px"}
                />
            )}
            <div
                className="total-order px-4"
                style={{
                    color: isCustomButton ? imgColor : "#FFF",
                    top: isSummery || isPrm ? "none" : "2rem",
                    bottom: isSummery || isPrm ? "1.5rem" : "none",
                    width: isPrm && isDialogCartOpend ? "42%" : "54%",
                    opacity: opacityStyle,
                }}
            >
                {DisplayedOrderPrice}
            </div>
        </div>
    );
}
