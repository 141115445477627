import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    background-color: transparent;
    position: relative;
    grid-template-rows:
        minmax(2%, min-content) minmax(2%, min-content) 1fr minmax(
            20%,
            min-content
        )
        minmax(20%, min-content);
    height: 100%;
`;
