import { CATEGORY_TYPE } from "@constants";
import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { AddRemoveActionsButton, InformationProductIcon } from "./";

export function SimpleProductActionsButtons({
    product,
    handleNutritionalInformationDialogOnCloseEvent,
    handleRemoveProductFromOrder,
    isAllergenIconActive,
    opacity,
    quantity,
}) {
    const { isInformationProductIconActive, informationProductIconContent } =
        useSelector((state) => state.settingSlice.productSetting, shallowEqual);
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );

    const active =
        product.showProductIcons && isAllergenIconActive === true
            ? true
            : false;

    return (
        product.type !== CATEGORY_TYPE && (
            <div
                className="button__clz mt-2"
                style={{ position: "absolute", zIndex: "9999" }}
            >
                <div
                    className="d-flex"
                    style={{
                        gap: active ? "3px" : "",
                    }}
                >
                    {!(
                        product.outOfStock &&
                        orderWorkflowItemSlice.isModification
                    ) && (
                        <InformationProductIcon
                            product={product}
                            isInformationProductIconActive={
                                isInformationProductIconActive
                            }
                            handleNutritionalInformationDialogOnCloseEvent={
                                handleNutritionalInformationDialogOnCloseEvent
                            }
                            informationProductIconContent={
                                informationProductIconContent
                            }
                        />
                    )}
                </div>

                <AddRemoveActionsButton
                    product={product}
                    opacity={opacity}
                    handleRemoveProductFromOrder={handleRemoveProductFromOrder}
                    quantity={quantity}
                />
            </div>
        )
    );
}
SimpleProductActionsButtons.propTypes = {
    product: PropTypes.object.isRequired,
    opacity: PropTypes.string,
    quantity: PropTypes.number,
    isAllergenIconActive: PropTypes.bool,
    handleNutritionalInformationDialogOnCloseEvent: PropTypes.func,
    handleRemoveProductFromOrder: PropTypes.func,
};
