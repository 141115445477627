import { useSnapshot } from "valtio";

import React from "react";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { AuthenticationErrorModal } from "@components/common/AuthenticationErrorModal";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { ConfirmationModalWrapper } from "@components/common/ConfirmationModalWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { SubStepsIconWrapper } from "@components/common/SubStepsIconWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";
import { SubStepsLogoWrapper } from "@components/common/SubStepsLogoWrapper";
import { UsualModeLanguagesWrapper } from "@components/common/UsualModeLanguageWrapper";
import { UsualModeSubStepContainerWrapper } from "@components/common/UsualModeSubStepContainerWrapper";
import { InputKeyboardWrapper } from "@components/InputKeyboardWrapper";
import { UsualNumericKeyboardWrapper } from "@components/UsualNumericKeyboardWrapper";

import {
    kioskStore,
    setKeyboardInputsValues,
    setNumericKeyboardInputValue,
    setPseudoNameValue,
} from "@pages/Kiosk/store";

export function UsualMode(): JSX.Element {
    const {
        isSubStepActionButtonsActive,
        subStepActionButtons,
        isAuthenticationErrorModalOpened,
        isConfirmationModalOpened,
        selectedInformationMode,
    } = useSnapshot(kioskStore);

    const activeSubStepActionsButtons = (
        subStepActionButtons as ActionsButtonsDesignItemType[]
    )
        .filter((item) => {
            return item.active === true;
        })
        .filter((item: ActionsButtonsDesignItemType) => {
            const IGNORE_BUTTON_ROLE = 2;
            const VALIDATE_BUTTON_ROLE = 1;
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [
                IGNORE_BUTTON_ROLE,
                VALIDATE_BUTTON_ROLE,
                BACK_BUTTON_ROLE,
            ];

            return ROLES.includes(item.role);
        })
        .sort((a, b) => {
            return a.role > b.role ? -1 : 1;
        });

    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(
        null
    );

    React.useEffect(() => {
        setNumericKeyboardInputValue("");
        setPseudoNameValue("");
        setKeyboardInputsValues({});
    }, []);

    return (
        <PreviewBackgroundWrapper selectedMode={selectedInformationMode}>
            <UsualModeSubStepContainerWrapper
                ref={(ref) => setContainerRef(ref)}
            >
                {isAuthenticationErrorModalOpened === true ? (
                    <AuthenticationErrorModal />
                ) : null}

                {containerRef ? (
                    isConfirmationModalOpened === true ? (
                        <ConfirmationModalWrapper containerRef={containerRef} />
                    ) : null
                ) : null}
                <SubStepsLogoWrapper />
                <SubStepsIconWrapper />
                <SubStepsInformationMessage />
                <div className="d-flex justify-content-center align-items-center">
                    {selectedInformationMode === "nameOrPseudo" ? (
                        <InputKeyboardWrapper />
                    ) : (
                        <UsualNumericKeyboardWrapper />
                    )}
                </div>
                <ActionsButtonsWrapper
                    actionsButtonsItems={activeSubStepActionsButtons}
                    actionButtonsActive={isSubStepActionButtonsActive}
                />
                <UsualModeLanguagesWrapper />
                <ConfigOptionsWrapper />
            </UsualModeSubStepContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
