import { useSnapshot } from "valtio";

import React from "react";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { AuthenticationErrorModal } from "@components/common/AuthenticationErrorModal";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { SubStepsIconWrapper } from "@components/common/SubStepsIconWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";
import { SubStepsLogoWrapper } from "@components/common/SubStepsLogoWrapper";
import { UsualModeLanguagesWrapper } from "@components/common/UsualModeLanguageWrapper";
import { UsualModeSubStepContainerWrapper } from "@components/common/UsualModeSubStepContainerWrapper";
import { UsualKeyboardWrapper } from "@components/UsualKeyboardWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export function UsualMode(): JSX.Element {
    const {
        subStepActionButtons,
        isSubStepActionButtonsActive,
        isAuthenticationErrorModalOpened,
        selectedAuthMode,
    } = useSnapshot(kioskStore);

    const activeSubStepActionsButtons = (
        subStepActionButtons as ActionsButtonsDesignItemType[]
    ).filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const IGNORE_BUTTON_ROLE = 2;
            const VALIDATE_BUTTON_ROLE = 1;
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [
                IGNORE_BUTTON_ROLE,
                VALIDATE_BUTTON_ROLE,
                BACK_BUTTON_ROLE,
            ];

            return ROLES.includes(item.role);
        }
    );

    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <PreviewBackgroundWrapper selectedMode={selectedAuthMode}>
            <UsualModeSubStepContainerWrapper>
                {isAuthenticationErrorModalOpened === true ? (
                    <AuthenticationErrorModal />
                ) : null}

                <SubStepsLogoWrapper />
                <SubStepsIconWrapper />
                <SubStepsInformationMessage />
                <div className="d-flex justify-content-center align-items-center">
                    <UsualKeyboardWrapper />
                </div>
                <ActionsButtonsWrapper
                    actionsButtonsItems={mainActiveActionsButtons}
                    actionButtonsActive={isSubStepActionButtonsActive}
                />
                <UsualModeLanguagesWrapper />
                <ConfigOptionsWrapper />
            </UsualModeSubStepContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
