import { useSnapshot } from "valtio";

import React from "react";

import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { HeaderWrapper } from "@components/common/HeaderWrapper";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { PrmActionsButtonsWrapper } from "@components/common/PrmActionsButtonsWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { PrmLanguagesWrapper } from "@components/common/PrmLanguageWrapper";
import { MainContentWrapper } from "@components/common/PrmModeMainContentWrapper";
import { GlobalPrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/GlobalPrmModeWrapper";
import { PrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/PrmModeWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export function PrmMode(): JSX.Element {
    const { actionsButtonsItems, isActionButtonsActive } =
        useSnapshot(kioskStore);

    const activeActionsButtons = actionsButtonsItems.filter((item) => {
        return item.active === true;
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <GlobalPrmModeWrapper>
            <PrmBackgroundWrapper />
            <div>
                <PreviewBackgroundWrapper>
                    <PrmModeWrapper>
                        <LogoWrapper />
                        <HeaderWrapper />
                        <MainContentWrapper />

                        <PrmActionsButtonsWrapper
                            actionsButtonsItems={mainActiveActionsButtons}
                            actionButtonsActive={isActionButtonsActive}
                        />

                        <div className="d-flex align-items-center justify-content-end">
                            <PrmLanguagesWrapper />

                            <ConfigOptionsWrapper />
                        </div>
                    </PrmModeWrapper>
                </PreviewBackgroundWrapper>
            </div>
        </GlobalPrmModeWrapper>
    );
}
