import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

type AuthenticationErrorMainType = {
    isGodiaLoyalty: boolean;
    isCreateAnAccountSelected: boolean;
    isCreateAnAccountActive: boolean;
};

export default function AuthenticationErrorMain({
    isGodiaLoyalty,
    isCreateAnAccountSelected,
    isCreateAnAccountActive,
}: AuthenticationErrorMainType): JSX.Element {
    const {
        project: {
            template: { pages },
        },
        selectedAuthMode,
    } = useSnapshot(kioskStore);
    return (
        <div className="d-flex flex-column auth-main-container-title">
            {isGodiaLoyalty === false ? (
                <div className="d-flex justify-content-center align-items-center w-100">
                    <span className="auth-main-title">
                        {t(pages.subSteps[selectedAuthMode].name)}
                    </span>
                </div>
            ) : null}
            <div className="d-flex justify-content-center align-items-center text-center">
                <span className="auth-main-description">
                    {isGodiaLoyalty &&
                    isCreateAnAccountSelected === false &&
                    isCreateAnAccountActive === true
                        ? t(
                              "Phone number not found, would you like to create an account?"
                          )
                        : isCreateAnAccountSelected === true &&
                          isCreateAnAccountActive === true
                        ? t("Phone number already exists.")
                        : selectedAuthMode !== "emailAndPassword"
                        ? t(
                              "Oups!! This number is not associated with a valid customer account"
                          )
                        : t("Your email or password is incorrect")}
                </span>
            </div>
        </div>
    );
}
