import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function HeaderWrapper(): JSX.Element {
    const {
        customerLanguage,
        isHeaderActive,
        isInformationMessageActive,
        informationMessageItems,
    } = useSnapshot(kioskStore);

    return (
        <div>
            {isHeaderActive && (
                <div>
                    {isInformationMessageActive ? (
                        <div className="d-flex text-center justify-content-center align-items-center">
                            <span style={{ fontSize: "55px" }}>
                                {
                                    informationMessageItems[
                                        customerLanguage.name
                                    ].content
                                }
                            </span>
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
}
