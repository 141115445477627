/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { getAlertActionButtonImageSrc } from "@helpers/general";

import "./index.css";

type AuthenticationErrorHeaderType = {
    handleCloseOnClick: () => void;
    actionsButtons: ActionsButtonsDesignType;
};

export default function AuthenticationErrorHeader({
    handleCloseOnClick,
    actionsButtons,
}: AuthenticationErrorHeaderType): JSX.Element {
    return (
        <div
            className="d-flex justify-content-center align-items-center w-100 auth-error-header"
            onClick={handleCloseOnClick}
        >
            <img
                alt="alert image"
                src={getAlertActionButtonImageSrc(
                    actionsButtons as ActionsButtonsDesignType
                )}
            />
        </div>
    );
}
