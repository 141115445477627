import { VALIDATED_EXTENSION_FILE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import {
    kioskStore,
    setFinalMessageSelectedInformationMode,
    setIsConfirmationModalOpened,
    setIsInformationModesConsommationModalOpened,
    setLocalSelectedInformationMode,
    setSelectedInformationMode,
} from "@pages/Kiosk/store";

type ImageContentPropsType = {
    item: ActionsButtonsDesignItemType;
};

export function ActionsButtonsImageContent({
    item,
}: ImageContentPropsType): JSX.Element {
    const {
        numericKeyboardInputValue,
        keyboardInputsValues,
        pseudoNameValue,
        saleMode,
        isUserAuthenticated,
    } = useSnapshot(kioskStore);

    const imageSrc = getImageContentById(item.id);

    function handleOnClickEvent(item: ActionsButtonsDesignItemType) {
        switch (item.name.toLowerCase()) {
            case "Validate".toLowerCase(): {
                setIsConfirmationModalOpened(true);

                break;
            }
            case "Back".toLowerCase(): {
                setIsInformationModesConsommationModalOpened(false);
                break;
            }
            case "Ignore".toLowerCase(): {
                if (
                    saleMode.name === "Delivery" &&
                    isUserAuthenticated === false
                ) {
                    setLocalSelectedInformationMode("");
                } else {
                    setFinalMessageSelectedInformationMode("none");
                    setSelectedInformationMode("");
                }
                setIsInformationModesConsommationModalOpened(false);
                break;
            }
        }
    }

    return (
        <React.Fragment>
            {VALIDATED_EXTENSION_FILE.includes(imageSrc.slice(-4)) ? (
                <img
                    src={imageSrc}
                    alt="action button"
                    onClick={() => {
                        handleOnClickEvent(item);
                    }}
                    style={{
                        height: "unset",
                        width: "unset",
                        opacity:
                            numericKeyboardInputValue === "" &&
                            keyboardInputsValues[t("Enter your email")] ===
                                undefined &&
                            keyboardInputsValues[t("Enter your password")] ===
                                undefined &&
                            pseudoNameValue === "" &&
                            imageSrc?.toLowerCase().includes("validate")
                                ? "0.5"
                                : 1,
                        pointerEvents:
                            numericKeyboardInputValue === "" &&
                            keyboardInputsValues[t("Enter your email")] ===
                                undefined &&
                            keyboardInputsValues[t("Enter your password")] ===
                                undefined &&
                            pseudoNameValue === "" &&
                            imageSrc?.toLowerCase().includes("validate")
                                ? "none"
                                : "auto",
                    }}
                />
            ) : (
                <DynamicSvgComponent
                    nameSvg={imageSrc as string}
                    width={"50%"}
                    height={"25vw"}
                    opacity={
                        numericKeyboardInputValue === "" &&
                        keyboardInputsValues[t("Enter your email")] ===
                            undefined &&
                        keyboardInputsValues[t("Enter your password")] ===
                            undefined &&
                        pseudoNameValue === "" &&
                        imageSrc?.toLowerCase().includes("validate")
                            ? "0.5"
                            : ("1" as string)
                    }
                    pointerEvents={
                        numericKeyboardInputValue === "" &&
                        keyboardInputsValues[t("Enter your email")] ===
                            undefined &&
                        keyboardInputsValues[t("Enter your password")] ===
                            undefined &&
                        pseudoNameValue === "" &&
                        imageSrc?.toLowerCase().includes("validate")
                            ? "none"
                            : "auto"
                    }
                />
            )}
        </React.Fragment>
    );
}
