import classNames from "classnames";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

export function SubStepsLogoWrapper(): JSX.Element {
    const { logo, isSubStepTopBannerActive, isSubStepLogoActive, isPrm } =
        useSnapshot(kioskStore);

    const imageSrc = getImageContentById(logo.id);

    return (
        <div className="d-flex justify-content-center align-items-center">
            {isSubStepTopBannerActive && isSubStepLogoActive ? (
                <img
                    src={imageSrc}
                    alt="logo"
                    className={classNames({
                        "usual-mode-logo__clz": !isPrm,
                        "prm-mode-logo__clz": isPrm,
                    })}
                />
            ) : null}
        </div>
    );
}
