import styled from "styled-components";

export const UsualModeSubStepContainerWrapper = styled.div`
    display: grid;
    background-color: transparent;
    position: relative;
    height: 100%;
    grid-template-rows:
        minmax(10%, min-content) minmax(10%, min-content) minmax(
            10%,
            min-content
        )
        1fr min-content min-content min-content;
`;
