import {
    ACCOUNT_OPTION,
    ALLERGENS_OPTION,
    ASK_FOR_HELP_BUTTON,
    CONNECT_USER_OPTION,
    EMPTY_BASKET_ICON_ROLE,
    FULL__BASKET_ICON_ROLE,
    GODIA_LOYALTY,
    INITIAL_USER,
    PRM_BUTTON,
    PRM_PAY_BUTTON_ROLE,
    USUAL_MODE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { PaymentActionButton } from "@components/OrderTaking/common/ActionButtonsImg";
import WorkflowAlert from "@components/OrderTaking/common/WorkflowAlert";
import AdditionalSale from "@components/OrderTaking/Components/Dialogs/AdditionalSale";
import AllergensDialog from "@components/OrderTaking/Components/Dialogs/Allergen/AllergensDialog";
import DialogCart from "@components/OrderTaking/Components/Dialogs/DialogCart";
import OrderSummary from "@components/OrderTaking/Components/Dialogs/OrderSummary";
import { CartIconEmpty } from "@components/OrderTaking/Components/Icons/CartIconEmpty";
import { CartIconFilled } from "@components/OrderTaking/Components/Icons/CartIconFilled";
import menuIcon from "@components/OrderTaking/Components/Icons/png_icons/menu-icon.png";
import { getAdditionnalSaleItems } from "@components/OrderTaking/Components/Products/ProductWrapper/hooks/useAdditionnalSaleItems";
import { getPrmOrdertakingDesignOptions } from "@components/OrderTaking/Helpers";
import {
    clearReward,
    setAdditionalItems,
    setUsedGifts,
} from "@components/OrderTaking/Store/feature";
import { syncStore } from "@components/Synchronization/store";

import {
    kioskStore,
    setAuthModalStepIndex,
    setIsCustomerBenefitOpen,
    setIsHelpModalOpened,
    setIsPrm,
    setIsUserAccountModalOpened,
    setNavigationIndex,
    setUser,
} from "@pages/Kiosk/store";

import { ConfigOptionItem } from "./ConfigOptionItem";
import { IconsMenu } from "./IconsMenu";
import "./index.css";

import "swiper/swiper.min.css";

export default function PrmBasket() {
    const { orderItems, totalPrice, itemsCount } = useSelector(
        (state) => state.orderSlice.order
    );
    const dispatch = useDispatch();

    const [isAdditionalSaleOpen, SetIsAdditionalSaleOpen] =
        React.useState(false);
    const {
        isPrm,
        activeConfigOptions,
        navigationIndex,
        isUserAuthenticated,
        devise,
        project: {
            template: {
                pages: {
                    subSteps: {
                        customerBenefit: {
                            options: {
                                gifts: { active: giftsActive },
                                rewards: { active: rewardsActive },
                                autoLaunchOnPayButton,
                            },
                        },
                    },
                },
            },
        },
    } = useSnapshot(kioskStore);
    const { selectedShopApp } = useSnapshot(syncStore);

    const [isAlertOpened, setIsAlertOpened] = React.useState(false);

    const { prmActionsButtons } = useSelector(
        (state) => state.settingSlice.prmSettings
    );
    const { orderTakingOptions } = useSelector((state) => state.settingSlice);

    const { designItems } = useSelector((state) => state.settingSlice);
    const emptyBasketIcon = designItems.filter((item) => {
        return item.role === EMPTY_BASKET_ICON_ROLE;
    });

    const { isGenericAdditionalSaleActive } = useSelector(
        (state) => state.orderSlice
    );

    const { globalcard } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const filledBasketIcon = designItems.filter((item) => {
        return item.role === FULL__BASKET_ICON_ROLE;
    });

    const allergenOption = designItems.find(
        (option) => option.name === ALLERGENS_OPTION
    );
    const accountOption = designItems.find(
        (option) => option.name === ACCOUNT_OPTION
    );
    let allergen = { ...allergenOption, title: allergenOption.name };
    let newOptionConfig = getPrmOrdertakingDesignOptions(
        orderTakingOptions,
        activeConfigOptions
    );

    if (allergenOption?.active === true) {
        newOptionConfig.push(allergen);
    }
    if (accountOption?.active === false) {
        newOptionConfig = newOptionConfig.filter((item) => {
            return item.title !== ACCOUNT_OPTION;
        });
    }

    const emptyBasketIconContent =
        emptyBasketIcon.length > 0
            ? getImageContentById(emptyBasketIcon[0].id)
            : "";

    const filledBasketIconContent =
        filledBasketIcon.length > 0
            ? getImageContentById(filledBasketIcon[0].id)
            : "";

    const DisplayedOrderPrice = `${totalPrice.toFixed(devise.decimalPrice)} ${
        devise.deviseSymbole
    }`;

    const message = "Would you like to abandon your order ?";

    const [isMenuOpened, setIsMenuOpened] = React.useState(false);
    const [openDialogCart, setOpenDialogCart] = React.useState(false);
    const [isLocalKeyBoardOpened, setIsLocalKeyBoardOpened] =
        React.useState(false);

    const [isAllergenDialogOpened, setIsAllergenDialogOpened] =
        React.useState(false);

    const [isSummaryOrderOpened, setIsSummaryOrderOpened] =
        React.useState(false);

    const additionalSale = isGenericAdditionalSaleActive
        ? getAdditionnalSaleItems(globalcard, "genericSalesAdd")
        : {
              name: "",
              listItems: "",
              selectedItems: [],
              parentId: "",
              design: { nbrRows: 2, nbrColumn: 2, isAutoDesign: true },
          };

    function handleOrderSummaryOnCLickEvent() {
        setIsSummaryOrderOpened(!isSummaryOrderOpened);
        if (
            selectedShopApp.fidelityType === GODIA_LOYALTY &&
            !isSummaryOrderOpened &&
            autoLaunchOnPayButton &&
            (giftsActive || rewardsActive)
        ) {
            setIsCustomerBenefitOpen(true);
        }
    }

    const handleAllergenDialogOnCLickEvent = () => {
        setIsAllergenDialogOpened(!isAllergenDialogOpened);
    };

    function handleConfigOptionOnClickEvent(option) {
        switch (option.title.toLowerCase()) {
            case PRM_BUTTON:
                setIsPrm(!isPrm);
                break;
            case USUAL_MODE:
                setIsPrm(!isPrm);
                break;
            case ASK_FOR_HELP_BUTTON:
                setIsHelpModalOpened(true);

                break;
            case CONNECT_USER_OPTION:
                if (isUserAuthenticated === false) {
                    setIsUserAccountModalOpened(true),
                        setAuthModalStepIndex(navigationIndex);
                }
                break;
            case "allergen":
                setIsAllergenDialogOpened(true);
                break;

            default:
                null;
        }
    }

    function handleBasketActionsButtonsOnClickEvent(button) {
        console.log(
            "🚀 ~ handleBasketActionsButtonsOnClickEvent ~ button:",
            button
        );
        if (orderItems.length === 0) {
            if (button.role !== PRM_PAY_BUTTON_ROLE) {
                setNavigationIndex(0);
                dispatch(clearReward());
                dispatch(setUsedGifts([]));
                setUser(INITIAL_USER);
            }
        } else {
            switch (button.role) {
                case PRM_PAY_BUTTON_ROLE:
                    if (
                        isGenericAdditionalSaleActive === true &&
                        additionalSale?.listItems?.length > 0
                    ) {
                        dispatch(setAdditionalItems(additionalSale));
                        SetIsAdditionalSaleOpen(true);
                    } else {
                        handleOrderSummaryOnCLickEvent();
                    }

                    setIsLocalKeyBoardOpened(false);
                    break;
                default:
                    setIsAlertOpened(!isAlertOpened);
                    break;
            }
        }
    }
    function handleAlertOnCLickEvent() {
        setIsAlertOpened(!isAlertOpened);
    }
    function onCloseWorkflowOnClickEvent() {
        dispatch(clearReward());
        dispatch(setUsedGifts([]));
        setUser(INITIAL_USER);
        setNavigationIndex(0);
        setIsAlertOpened(!isAlertOpened);
    }

    var option;
    const UsualModeOption = activeConfigOptions.filter(
        (config) => config.title.toLowerCase() === USUAL_MODE
    )[0];
    const imageSrc = getImageContentById(UsualModeOption.id);

    return (
        <>
            {isAdditionalSaleOpen && (
                <AdditionalSale
                    open={isAdditionalSaleOpen}
                    onClose={SetIsAdditionalSaleOpen}
                    handleOrderSummaryOnCLickEvent={
                        handleOrderSummaryOnCLickEvent
                    }
                />
            )}

            {isAllergenDialogOpened && (
                <AllergensDialog
                    open={isAllergenDialogOpened}
                    onClose={handleAllergenDialogOnCLickEvent}
                />
            )}
            {isSummaryOrderOpened && (
                <OrderSummary
                    open={isSummaryOrderOpened}
                    onClose={handleOrderSummaryOnCLickEvent}
                    setIsSummaryOrderOpened={setIsSummaryOrderOpened}
                    openDialogCart={openDialogCart}
                    setOpenDialogCart={setOpenDialogCart}
                    isSummaryOrderOpened={isSummaryOrderOpened}
                    setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                />
            )}
            {openDialogCart && (
                <DialogCart
                    openDialogCart={openDialogCart}
                    setOpenDialogCart={setOpenDialogCart}
                    isSummaryOrderOpened={isSummaryOrderOpened}
                    setIsSummaryOrderOpened={setIsSummaryOrderOpened}
                    isLocalKeyBoardOpened={isLocalKeyBoardOpened}
                    setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                />
            )}
            {isAlertOpened && (
                <WorkflowAlert
                    open={isAlertOpened}
                    onClose={handleAlertOnCLickEvent}
                    onCloseWorkflowOnClickEvent={onCloseWorkflowOnClickEvent}
                    message={message}
                />
            )}

            {prmActionsButtons
                .filter((item) => {
                    return item.active === true;
                })
                .sort((a, b) => {
                    return a.role > b.role ? -1 : 1;
                })
                .map((button, index) => {
                    const imageSrc = getImageContentById(button.id);
                    const opacityStyle =
                        button.name.toLowerCase() === "pay pmr" &&
                        orderItems.length === 0
                            ? "0.5"
                            : "1";

                    return button.name.toLowerCase() === "pay pmr" ? (
                        <PaymentActionButton
                            isDialogCartOpend={true}
                            imageUrl={imageSrc}
                            PayButton={button}
                            opacityStyle={opacityStyle}
                            DisplayedOrderPrice={DisplayedOrderPrice}
                            handleBasketActionsButtonsOnClickEvent={
                                handleBasketActionsButtonsOnClickEvent
                            }
                            width={"100%"}
                        />
                    ) : (
                        <div
                            key={index}
                            className="d-flex align-items-center justify-content-center "
                            style={{ width: "30%", height: "100%" }}
                            onClick={() => {
                                handleBasketActionsButtonsOnClickEvent(button);
                            }}
                        >
                            {VALIDATED_EXTENSION_FILE.includes(
                                imageSrc.slice(-4)
                            ) ? (
                                <img
                                    key={index}
                                    src={imageSrc}
                                    style={{
                                        width: "100%",
                                        height: "65px",
                                    }}
                                />
                            ) : (
                                <DynamicSvgComponent
                                    nameSvg={imageSrc}
                                    width={"275px"}
                                    height={"65px"}
                                />
                            )}
                        </div>
                    );
                })}

            <div
                id="prm-basket-cart"
                className="prm-cart-icon"
                onClick={() => setOpenDialogCart(true)}
                disabled={itemsCount === 0}
            >
                {itemsCount === 0 ? (
                    emptyBasketIconContent !== "" ? (
                        <img
                            className="w-50 h-100"
                            src={emptyBasketIconContent}
                            alt={emptyBasketIconContent}
                        />
                    ) : (
                        <CartIconEmpty />
                    )
                ) : filledBasketIconContent !== "" ? (
                    <img
                        className="w-50 h-100"
                        src={filledBasketIconContent}
                        alt={
                            itemsCount === 0
                                ? emptyBasketIconContent
                                : filledBasketIconContent
                        }
                    />
                ) : (
                    <CartIconFilled />
                )}

                <span className="prm-number-articles">{itemsCount}</span>
            </div>

            <div
                className="pmr-icon"
                onClick={() => {
                    setIsPrm(!isPrm);
                }}
            >
                <img
                    src={imageSrc}
                    style={{
                        height: "65px",
                        width: "65px",
                        boxShadow: "0px 3px 6px #00000080",
                        borderRadius: "15px",
                    }}
                />
            </div>
            {isMenuOpened ? (
                <IconsMenu
                    isOpened={isMenuOpened}
                    setIsMenuOpened={setIsMenuOpened}
                    newOptionConfig={newOptionConfig}
                />
            ) : null}

            {newOptionConfig.length > 2 ? (
                isMenuOpened === false ? (
                    <div className="pmr-menu-icon">
                        <img
                            src={menuIcon}
                            onClick={() => {
                                setIsMenuOpened(!isMenuOpened);
                            }}
                        />
                    </div>
                ) : null
            ) : newOptionConfig.filter(
                  (option) =>
                      option.title.toLowerCase() !== PRM_BUTTON &&
                      option.title.toLowerCase() !== USUAL_MODE
              )[0] ? (
                ((option = newOptionConfig.filter(
                    (option) =>
                        option.title.toLowerCase() !== PRM_BUTTON &&
                        option.title.toLowerCase() !== USUAL_MODE
                )[0]),
                (
                    <div className="pb-2">
                        <ConfigOptionItem
                            option={option}
                            handleConfigOptionOnClickEvent={
                                handleConfigOptionOnClickEvent
                            }
                        />
                    </div>
                ))
            ) : null}
        </>
    );
}
