import { useSnapshot } from "valtio";

import React from "react";

import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { PrmActionsButtonsWrapper } from "@components/common/PrmActionsButtonsWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { PrmLanguagesWrapper } from "@components/common/PrmLanguageWrapper";
import { GlobalPrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/GlobalPrmModeWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";

import { kioskStore } from "@pages/Kiosk/store";

import { QRCodeWrapper } from "../QRCodeWrapper";

type prmModeType = {
    setIsQrCodeShown: React.Dispatch<React.SetStateAction<boolean>>;
    isQrCodeShown: boolean;
    handleOnChangeEvent: (e: React.ChangeEvent<HTMLInputElement>) => void;
    inputRef: React.RefObject<HTMLInputElement>;
    isScanningQrCode: boolean;
};

export function PrmMode({
    isQrCodeShown,
    setIsQrCodeShown,
    handleOnChangeEvent,
    inputRef,
    isScanningQrCode,
}: prmModeType): JSX.Element {
    const { isSubStepActionButtonsActive, subStepActionButtons } =
        useSnapshot(kioskStore);
    const activeSubStepActionsButtons = (
        subStepActionButtons as ActionsButtonsDesignItemType[]
    ).filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [BACK_BUTTON_ROLE];

            return ROLES.includes(item.role);
        }
    );
    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <GlobalPrmModeWrapper>
            <PrmBackgroundWrapper />
            <div>
                <PreviewBackgroundWrapper selectedMode={"clickAndCollect"}>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateRows: "15fr 20fr 7fr 5fr",
                            height: "100%",
                        }}
                    >
                        <SubStepsInformationMessage />

                        <QRCodeWrapper
                            isQrCodeShown={isQrCodeShown}
                            setIsQrCodeShown={setIsQrCodeShown}
                            handleOnChangeEvent={handleOnChangeEvent}
                            inputRef={inputRef}
                        />
                        <PrmActionsButtonsWrapper
                            actionsButtonsItems={mainActiveActionsButtons}
                            actionButtonsActive={isSubStepActionButtonsActive}
                        />
                        <div className="d-flex align-items-center justify-content-end">
                            <PrmLanguagesWrapper
                                isScanningQrCode={isScanningQrCode}
                            />
                            <ConfigOptionsWrapper
                                isScanningQrCode={isScanningQrCode}
                            />
                        </div>
                    </div>
                </PreviewBackgroundWrapper>
            </div>
        </GlobalPrmModeWrapper>
    );
}
