import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function FinalMessageWrapper(): JSX.Element {
    const {
        customerLanguage,
        project: {
            template: { content },
        },
        finalMessageSelectedInformationMode,
        finalMessageSelectedRegulationMode,
    } = useSnapshot(kioskStore);

    return (
        <div
            className="d-flex justify-content-center align-items-center text-center mx-3"
            style={{
                fontSize: "35px",
            }}
        >
            {
                (content.finalMessage as FinalMessageContentType).items.filter(
                    (item: FinalMessageItemType) => {
                        return (
                            item.title === finalMessageSelectedRegulationMode
                        );
                    }
                )[0][finalMessageSelectedInformationMode].languages[
                    customerLanguage.name
                ].content
            }
        </div>
    );
}
