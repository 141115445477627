import {
    CATALOGUE_MODE,
    NO_THANKS_BUTTON_ROLE,
    TCPOS,
    VALIDATE_BUTTON_ROLE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { Dialog } from "@mui/material";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { store } from "@store";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import PrmProducts from "@components/OrderTaking/Components/Products/ProductWrapper/PmrProducts";
import { addItemCommandTcPos } from "@components/OrderTaking/Helpers";
import {
    setAdditionalItems,
    setNextWorkflowStep,
    validateAdditionnalItems,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import { ListItems } from "../../Products/ProductWrapper/ListItems";
import "./index.css";

export default function AdditionalSale({
    open,
    onClose,
    handleOrderSummaryOnCLickEvent,
}) {
    const { posEditor } = useSnapshot(store);
    const dispatch = useDispatch();
    const {
        isPrm,
        project: {
            template: { content },
        },
    } = useSnapshot(kioskStore);
    const { additionalSale } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const { actionsButtons } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    const noThanksButton = actionsButtons.filter((item) => {
        return item.active === true && item.role === NO_THANKS_BUTTON_ROLE;
    })[0];

    const validateButton = content.actionsButtons.items.filter((item) => {
        return item.role === VALIDATE_BUTTON_ROLE;
    })[0];
    const validateImageSrc = getImageContentById(validateButton.id);

    const noThnxImageSrc = getImageContentById(noThanksButton.id);

    const { workflowData } = useSelector(
        (state) => state.workFlowDataSlice,
        shallowEqual
    );

    const openOrderSummary = () => {
        if (handleOrderSummaryOnCLickEvent !== undefined) {
            handleOrderSummaryOnCLickEvent();
        }
    };
    function skipStep() {
        if (
            workflowData.length > 0 &&
            workflowData[workflowData.length - 1].index <
                workflowData[workflowData.length - 1].step &&
            workflowData[workflowData.length - 1].workflow[
                workflowData[workflowData.length - 1].index
            ].NbrOfChoices ===
                workflowData[workflowData.length - 1].workflow[
                    workflowData[workflowData.length - 1].index
                ].maxNbrOfChoices &&
            workflowData[workflowData.length - 1].workflow[
                workflowData[workflowData.length - 1].index
            ].haveComment === false
        ) {
            dispatch(setNextWorkflowStep());
        }
    }

    function handleAddtoOrderOnCLickEvent() {
        if (!(posEditor === TCPOS)) {
            dispatch(validateAdditionnalItems(additionalSale?.selectedItems));
        } else {
            additionalSale?.selectedItems.forEach((product) => {
                dispatch(addItemCommandTcPos(product));
            });
        }
        dispatch(
            setAdditionalItems({
                name: "",
                listItems: [],
                selectedItems: [],
            })
        );
        skipStep();
    }
    function handleCancelOnCLickEvent() {
        dispatch(
            setAdditionalItems({
                name: "",
                listItems: [],
                selectedItems: [],
            })
        );
        skipStep();
    }
    return (
        <Dialog
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    width: "944px!important",
                    height: isPrm ? "35%!important" : "1724px!important",
                    boxShadow: "0px 3px 6px #00000029!important",
                    border: "1px solid #707070!important",
                    borderRadius: "65px!important",
                    borderTop: `15px solid var(--global-color)!important`,
                    maxWidth: "unset!important",
                    paddingTop: "25px!important",
                    paddingBottom: "25px!important",
                    display: "flex",
                    justifyContent: "start",
                    maxHeight: "unset",
                    alignItems: "center!important",
                    top: isPrm ? "32%!important" : "unset!important",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
        >
            {additionalSale?.message !== "" ? (
                <div className="column-centred-items-additional-sale header-additional-sale-dialog">
                    <span
                        className="two-lines-preview-text title-additional-sale"
                        style={{
                            textAlign: "center",
                        }}
                    >
                        {additionalSale?.message}
                    </span>
                </div>
            ) : (
                <div className="column-centred-items-additional-sale header-additional-sale-dialog">
                    <span className="text-header-additional-sale">
                        {t("Do you want")}
                    </span>
                    <span
                        className="two-lines-preview-text title-additional-sale"
                        style={{
                            textAlign: "center",
                        }}
                    >
                        {additionalSale?.name}
                    </span>
                </div>
            )}
            {!isPrm ? <hr className="separation-line" /> : null}
            <div className="list-additional-products">
                {!isPrm ? (
                    <ListItems
                        isAdditionalSale={true}
                        listAdditionnalItems={additionalSale?.listItems}
                    />
                ) : (
                    <PrmProducts
                        isAdditionalSale={true}
                        listAdditionnalItems={additionalSale?.listItems}
                    />
                )}
            </div>
            {!isPrm ? <hr className="separation-line" /> : null}
            <div className="centered-items w-100" style={{ height: "150px" }}>
                {noThnxImageSrc !== "" ? (
                    <div
                        className="additional-sale-button  mx-3"
                        onClick={() => {
                            openOrderSummary();
                            handleCancelOnCLickEvent();
                            onClose();
                        }}
                    >
                        {VALIDATED_EXTENSION_FILE.includes(
                            noThnxImageSrc.slice(-4)
                        ) ? (
                            <img
                                src={noThnxImageSrc}
                                style={{
                                    height: "87px",
                                    width: "350px",
                                }}
                            />
                        ) : (
                            <DynamicSvgComponent
                                nameSvg={noThnxImageSrc}
                                width={"350px"}
                                height={"87px"}
                            />
                        )}
                    </div>
                ) : (
                    <button
                        className="additional-sale-button mx-2"
                        style={{
                            background: `var(--global-color)`,
                        }}
                        onClick={() => {
                            openOrderSummary();
                            handleCancelOnCLickEvent();
                            onClose();
                        }}
                    >
                        <div className="text-button">{t("No thanks")}</div>
                    </button>
                )}
                {additionalSale?.selectedItems.length > 0 &&
                    (validateImageSrc !== "" ? (
                        <div
                            className="additional-sale-button mx-3"
                            onClick={() => {
                                openOrderSummary();
                                onClose();
                                handleAddtoOrderOnCLickEvent();
                            }}
                        >
                            {VALIDATED_EXTENSION_FILE.includes(
                                validateImageSrc.slice(-4)
                            ) ? (
                                <img
                                    src={validateImageSrc}
                                    style={{
                                        height: "87px",
                                        width: "350px",
                                    }}
                                />
                            ) : (
                                <DynamicSvgComponent
                                    nameSvg={validateImageSrc}
                                    width={"350px"}
                                    height={"87px"}
                                />
                            )}
                        </div>
                    ) : (
                        <button
                            className="additional-sale-button"
                            style={{
                                background: `var(--global-color)`,
                            }}
                            onClick={() => {
                                openOrderSummary();
                                onClose();
                                handleAddtoOrderOnCLickEvent();
                            }}
                        >
                            <div className="text-button">{t("Validate")}</div>
                        </button>
                    ))}
            </div>
        </Dialog>
    );
}

AdditionalSale.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleOrderSummaryOnCLickEvent: PropTypes.func,
};
