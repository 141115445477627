/* eslint-disable @typescript-eslint/no-explicit-any */
import { INITIAL_USER, LOGOUT_BUTTON_ID } from "@constants";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import {
    getColorFromButton,
    getGlobalColorValue,
} from "@components/OrderTaking/Helpers";
import {
    clearReward,
    setUsedGifts,
} from "@components/OrderTaking/Store/feature";

import {
    kioskStore,
    setIsCustomerBenefitOnClick,
    setIsCustomerBenefitOpen,
    setIsUserAuthenticated,
    setUser,
} from "@pages/Kiosk/store";

import { PromoCodeSection } from "./PromoSection";
import {
    AuthenticateButtonContainer,
    GreetingSectionContainer,
    HeaderContainer,
    LoyaltyBalanceContainer,
    PromoCodeScanContainer,
} from "./styledCss";

export default function Header(): JSX.Element | null {
    const {
        project: {
            template: {
                pages: {
                    subSteps: {
                        customerBenefit: {
                            actionsButtons,
                            options: { customerBenefitHeader, promoCode },
                        },
                    },
                },
            },
        },
        customerLanguage,
        user,
    } = useSnapshot(kioskStore);
    const [isPromoModelOpen, setIsPromoModelOpen] =
        React.useState<boolean>(false);

    const dispatch = useDispatch();

    const colorValue = getGlobalColorValue("--global-color");
    const inputColor = getColorFromButton(colorValue);

    const logoutBtn = actionsButtons[LOGOUT_BUTTON_ID];
    const greetingMsg: string =
        customerBenefitHeader.informationMessage.languages[
            customerLanguage.name
        ] ?? customerBenefitHeader.informationMessage.resetContent;

    const customerBalanceMsg: string =
        customerBenefitHeader.customerBalance.languages[
            customerLanguage.name
        ] ?? customerBenefitHeader.customerBalance.resetContent;

    function handleLogoutUser() {
        dispatch(clearReward());
        dispatch(setUsedGifts([]));
        setIsUserAuthenticated(false);
        setUser(INITIAL_USER);
        setIsCustomerBenefitOpen(false);
        setIsCustomerBenefitOnClick(false);
    }

    return (
        <>
            <PromoCodeSection
                setIsPromoModelOpen={setIsPromoModelOpen}
                isPromoModelOpen={isPromoModelOpen}
            />
            <HeaderContainer>
                {customerBenefitHeader.informationMessage.active ? (
                    <GreetingSection
                        greetingMsg={`${greetingMsg} ${
                            user.firstName.length > 0
                                ? user.firstName
                                : user.lastName
                        }`}
                    />
                ) : null}
                {logoutBtn.active ? (
                    <AuthenticateButton
                        logoutBtnMsg={logoutBtn.name}
                        handleLogoutUser={handleLogoutUser}
                    />
                ) : null}
                {customerBenefitHeader.customerBalance.active ? (
                    <LoyaltyBalance customerBalanceMsg={customerBalanceMsg} />
                ) : null}
                {promoCode.active ? (
                    <PromoCodeScanContainer
                        onClick={() => setIsPromoModelOpen(true)}
                        inputColor={inputColor}
                    >
                        {t("Scan or enter promo code")}
                        <div style={{ marginRight: "20px" }}>
                            <DynamicSvgComponent
                                nameSvg="promoCode"
                                width={"50px"}
                                height={"50px"}
                                color={inputColor}
                            />
                        </div>
                    </PromoCodeScanContainer>
                ) : null}
            </HeaderContainer>
        </>
    );
}

type greetingSectionType = {
    greetingMsg: string;
};

function GreetingSection({ greetingMsg }: greetingSectionType): JSX.Element {
    return (
        <GreetingSectionContainer>{greetingMsg} 👋</GreetingSectionContainer>
    );
}

type authenticateButtonType = {
    logoutBtnMsg: string;
    handleLogoutUser: () => void;
};

function AuthenticateButton({
    logoutBtnMsg,
    handleLogoutUser,
}: authenticateButtonType): JSX.Element {
    const colorValue = getGlobalColorValue("--secondary-color");
    const inputColor = getColorFromButton(colorValue);
    return (
        <AuthenticateButtonContainer
            onClick={handleLogoutUser}
            inputColor={inputColor}
        >
            {t(`${logoutBtnMsg}`)}
            <PowerSettingsNewIcon
                fontSize="medium"
                style={{ color: inputColor }}
            />
        </AuthenticateButtonContainer>
    );
}

type loyaltyBalanceType = {
    customerBalanceMsg: string;
};

function LoyaltyBalance({
    customerBalanceMsg,
}: loyaltyBalanceType): JSX.Element {
    const { loyaltyAccount } = useSelector(
        (state: any) => state.orderSlice.userRewards
    );
    return (
        <LoyaltyBalanceContainer>
            <span>{`${customerBalanceMsg} ${loyaltyAccount || 0} points`}</span>
        </LoyaltyBalanceContainer>
    );
}
