import { BASIC_COMPOSITION_TYPE, IS_WORKFLOW_TYPE, TCPOS } from "@constants";
import Collapse from "@mui/material/Collapse";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { store } from "@store";

import MessageIcon from "@components/OrderTaking/Components/Icons/MessageIcon";
import { RemoveIcon } from "@components/OrderTaking/Components/Icons/RemoveIcon";
import {
    refactorGroupedItems,
    removeItemCommandTcPos,
    useToggle,
} from "@components/OrderTaking/Helpers";
import {
    minusNbrChoiceOfCurrentStep,
    removeFromOrder,
    removeFromWorkFlowItemShopCart,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";
import ProductCardPopup from "./ProductCardPopup";

export default function WorkflowSubMenu({
    product,
    isRemovedProductDialog,
    isWorkflow,
    isSalesModeChange,
}) {
    const { posEditor } = useSnapshot(store);
    const dispatchStore = useDispatch();

    const [isExpanded, setIsExpanded] = useToggle();

    const { cardType } = useSelector((state) => state.orderSlice, shallowEqual);

    const { isClickAndCollect, devise } = useSnapshot(kioskStore);

    const isExpandMode =
        product.compositions !== undefined &&
        Object.keys(product.compositions).length > 0;

    function removeProduct() {
        if (isWorkflow === IS_WORKFLOW_TYPE) {
            dispatchStore(
                removeFromWorkFlowItemShopCart({ product, cardType })
            );
            dispatchStore(minusNbrChoiceOfCurrentStep());
        } else {
            if (TCPOS === posEditor) {
                dispatchStore(removeItemCommandTcPos(product));
            } else {
                dispatchStore(removeFromOrder(product));
            }
        }
    }
    const groupedItems =
        product.compositions !== undefined &&
        Object.keys(product.compositions).length > 0
            ? product.compositions[Object.keys(product.compositions)[0]]
                  .type !== BASIC_COMPOSITION_TYPE
                ? refactorGroupedItems(product.compositions)
                : Object.keys(
                      product.compositions[Object.keys(product.compositions)[0]]
                          .compositions
                  ).length > 0
                ? refactorGroupedItems(product.compositions)
                : []
            : [];

    return (
        <div
            className="p-4 composed-product"
            style={{
                maxHeight: !isExpanded ? "316px" : "",
                minHeight: !isExpanded ? "180px" : "",
            }}
        >
            <div className="header-composed-product mb-2">
                <>
                    <div className="d-flex justify-content-between">
                        <div
                            className="d-flex align-items-center "
                            style={{
                                gap: "0.8rem",
                            }}
                        >
                            <div
                                className="d-flex h-100"
                                style={{
                                    flexDirection: isSalesModeChange
                                        ? "row-reverse"
                                        : "",
                                }}
                            >
                                <div className="d-flex align-items-center justify-content-center title-product mx-2">
                                    <span className="number-product">
                                        {product.quantity} X
                                    </span>
                                </div>

                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{
                                        maxWidth: "120px",
                                        maxHeight: "120px",
                                    }}
                                >
                                    <img
                                        src={product.urlImage}
                                        style={{
                                            borderRadius: "10%",
                                            height: "auto",
                                            width: "100%",
                                        }}
                                    />
                                </div>
                            </div>

                            <div
                                className="d-flex flex-column"
                                style={{ gap: "5px" }}
                            >
                                <span className="recap-product-title two-lines-preview-text">{`${product.displayTitle}`}</span>
                                <span className="summary-options-product">
                                    {` ${
                                        product.selectedOptions
                                            ? product.selectedOptions?.join(",")
                                            : ""
                                    }`}
                                </span>
                                {!isSalesModeChange ? (
                                    <div className="recap-price-product">
                                        {product?.price.toFixed(
                                            devise.decimalPrice
                                        ) + ` ${devise.deviseSymbole}`}
                                    </div>
                                ) : product.isDeleted ? (
                                    <div className="d-flex w-25">
                                        <img
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                            }}
                                            src="./images/crossIcon.png"
                                        />
                                        <span
                                            style={{
                                                font: "normal normal bold 12px/21px Segoe UI",
                                                textAlign: "center",
                                                width: "166px",
                                                color: "#ff0000",
                                            }}
                                        >
                                            {t("will be deleted")}
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {!isSalesModeChange ? (
                            <>
                                <div
                                    className="recap-product-operations align-items-center"
                                    style={{
                                        height: "fit-content",
                                        gap: "10px",
                                    }}
                                >
                                    {!isRemovedProductDialog &&
                                    !product.disabled ? (
                                        <div
                                            className="d-flex justify-content-center align-content-center p-1"
                                            style={{
                                                width: "55px",
                                                height: "55px",

                                                borderRadius: " 10px",
                                            }}
                                        >
                                            <MessageIcon
                                                width="45"
                                                height="45"
                                                color={
                                                    product.comment !== ""
                                                        ? "var(--global-color)"
                                                        : "gray"
                                                }
                                            />
                                        </div>
                                    ) : null}
                                    {isClickAndCollect ||
                                    product.disabled ? null : (
                                        <div className="d-flex">
                                            <RemoveIcon
                                                width={72}
                                                height={69}
                                                onClick={removeProduct}
                                            ></RemoveIcon>
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : null}
                    </div>
                </>
            </div>
            {(isExpandMode && groupedItems.length > 0) ||
            (!isRemovedProductDialog &&
                !isSalesModeChange &&
                groupedItems.length > 0) ? (
                <>
                    <div
                        className={isExpanded ? "mb-5" : ""}
                        style={{
                            overflowY: "auto",
                            scrollbarWidth: "none",
                        }}
                    >
                        <Collapse in={isExpanded}>
                            <div className="d-flex flex-column align-items-center">
                                {groupedItems.map((Item, index) => {
                                    const TitleStepItem = Object.keys(Item)[0];
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="title-element my-2">
                                                {TitleStepItem !== undefined &&
                                                TitleStepItem !==
                                                    BASIC_COMPOSITION_TYPE
                                                    ? TitleStepItem
                                                    : t(
                                                          "BASIC COMPOSITION"
                                                      ).toUpperCase()}
                                            </div>
                                            {Item[TitleStepItem].name !==
                                                undefined ||
                                            TitleStepItem ===
                                                BASIC_COMPOSITION_TYPE ? (
                                                <ProductCardPopup
                                                    Item={
                                                        TitleStepItem ===
                                                        BASIC_COMPOSITION_TYPE
                                                            ? Item[
                                                                  TitleStepItem
                                                              ].compositions[0]
                                                            : Item[
                                                                  TitleStepItem
                                                              ]
                                                    }
                                                />
                                            ) : (
                                                Item[
                                                    TitleStepItem
                                                ].compositions.map((Item2) => {
                                                    return (
                                                        Item2.name && (
                                                            <ProductCardPopup
                                                                Item={Item2}
                                                                isSalesModeChange={
                                                                    isSalesModeChange
                                                                }
                                                                isRemovedProductDialog={
                                                                    isRemovedProductDialog
                                                                }
                                                            />
                                                        )
                                                    );
                                                })
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </Collapse>
                    </div>

                    <button onClick={setIsExpanded} className="view-more ">
                        {isExpanded ? t("Hide details") : t("See details")}
                    </button>
                </>
            ) : null}
        </div>
    );
}

WorkflowSubMenu.propTypes = {
    styles: PropTypes.object,
    product: PropTypes.object.isRequired,
    productClickHandler: PropTypes.func,
    closeOrderSummury: PropTypes.func,
    isRemovedProductDialog: PropTypes.bool,
    isWorkflow: PropTypes.bool,
    isSalesModeChange: PropTypes.bool,
    isDialogCart: PropTypes.bool,
    isPrmDialogCart: PropTypes.bool,
};
