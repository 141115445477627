import { useSnapshot } from "valtio";

import React from "react";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { SubStepsIconWrapper } from "@components/common/SubStepsIconWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";
import { SubStepsLogoWrapper } from "@components/common/SubStepsLogoWrapper";
import { UsualModeLanguagesWrapper } from "@components/common/UsualModeLanguageWrapper";
import { UsualModeSubStepContainerWrapper } from "@components/common/UsualModeSubStepContainerWrapper";

import { kioskStore } from "@pages/Kiosk/store";

import { QRCodeWrapper } from "../QRCodeWrapper";
import "./index.css";

type usualModeType = {
    setIsQrCodeShown: React.Dispatch<React.SetStateAction<boolean>>;
    isQrCodeShown: boolean;
    handleOnChangeEvent: (e: React.ChangeEvent<HTMLInputElement>) => void;
    inputRef: React.RefObject<HTMLInputElement>;
    isScanningQrCode: boolean;
};

export function UsualMode({
    isQrCodeShown,
    setIsQrCodeShown,
    handleOnChangeEvent,
    inputRef,
    isScanningQrCode,
}: usualModeType): JSX.Element {
    const { isSubStepActionButtonsActive, subStepActionButtons } =
        useSnapshot(kioskStore);

    const activeSubStepActionsButtons = (
        subStepActionButtons as ActionsButtonsDesignItemType[]
    ).filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [BACK_BUTTON_ROLE];

            return ROLES.includes(item.role);
        }
    );

    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <PreviewBackgroundWrapper selectedMode={"clickAndCollect"}>
            <UsualModeSubStepContainerWrapper>
                <SubStepsLogoWrapper />
                <SubStepsIconWrapper />
                <SubStepsInformationMessage />
                <QRCodeWrapper
                    isQrCodeShown={isQrCodeShown}
                    setIsQrCodeShown={setIsQrCodeShown}
                    handleOnChangeEvent={handleOnChangeEvent}
                    inputRef={inputRef}
                />

                <ActionsButtonsWrapper
                    actionsButtonsItems={mainActiveActionsButtons}
                    actionButtonsActive={isSubStepActionButtonsActive}
                />
                <UsualModeLanguagesWrapper
                    isScanningQrCode={isScanningQrCode}
                />
                <ConfigOptionsWrapper isScanningQrCode={isScanningQrCode} />
            </UsualModeSubStepContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
