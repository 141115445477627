/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    BACK_BUTTON_ROLE,
    VALIDATE_BUTTON_ROLE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

type AuthenticationErrorFooterType = {
    handleOnClickEvent: (item: any) => void;
    isCreateAnAccountActive: boolean;
    isCreateAnAccountSelected: boolean;
    isGodiaLoyalty: boolean;
};

export default function AuthenticationErrorFooter({
    handleOnClickEvent,
    isCreateAnAccountActive,
    isCreateAnAccountSelected,
    isGodiaLoyalty,
}: AuthenticationErrorFooterType): JSX.Element {
    const {
        project: {
            template: {
                content: { actionsButtons },
            },
        },
    } = useSnapshot(kioskStore);
    const actionButtons = (
        actionsButtons as ActionsButtonsDesignType
    ).items.filter((item) => {
        return (
            item.role === VALIDATE_BUTTON_ROLE || item.role === BACK_BUTTON_ROLE
        );
    });
    // eslint-disable-next-line
    const mainActiveActionsButtons = actionButtons
        .sort((a: any, b: any) => {
            return a.role > b.role ? -1 : 1;
        })
        .map((item) => {
            return { ...item, imgContent: getImageContentById(item.id) };
        });
    return isGodiaLoyalty &&
        isCreateAnAccountActive &&
        isCreateAnAccountSelected === false ? (
        <div className="mx-3 d-flex justify-content-center align-items-center auth-error-footer-container">
            {mainActiveActionsButtons.map((item) => {
                return VALIDATED_EXTENSION_FILE.includes(
                    item.imgContent.slice(-4)
                ) ? (
                    <img
                        key={item.id}
                        src={item.imgContent}
                        alt="action button"
                        className="auth-error-footer-image"
                        onClick={() => handleOnClickEvent(item)}
                    />
                ) : (
                    <DynamicSvgComponent
                        key={item.id}
                        nameSvg={item.imgContent}
                        width={"350px"}
                        height={"87px"}
                        pointerEvents={"auto"}
                        onClick={() => handleOnClickEvent(item)}
                    />
                );
            })}
        </div>
    ) : (
        <div className="d-flex justify-content-center align-items-center w-100">
            <span className="auth-error-footer-description">
                {t("Touch the screen to continue")}
            </span>
        </div>
    );
}
