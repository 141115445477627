/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    CATALOGUE_MODE,
    CREATE_AN_ACCOUNT_ID,
    GODIA_LOYALTY,
} from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { syncStore } from "@components/Synchronization/store";

import {
    setIsAuthenticationErrorModalOpened,
    setIsCreateAnAccountSelected,
    setKeyboardInputsValues,
    setNavigationIndex,
    kioskStore as store,
} from "@pages/Kiosk/store";

import AuthenticationErrorFooter from "./AuthenticationErrorFooter";
import AuthenticationErrorHeader from "./AuthenticationErrorHeader";
import AuthenticationErrorMain from "./AuthenticationErrorMain";
import "./index.css";

export function AuthenticationErrorModal(): JSX.Element {
    const {
        isPrm,
        isAuthenticationErrorModalOpened,
        project: {
            template: {
                content: { generalDesign, actionsButtons, connection },
            },
        },
        kioskWays,
        isCreateAnAccountSelected,
    } = useSnapshot(store);
    const { selectedShopApp } = useSnapshot(syncStore);

    const isCreateAnAccountActive =
        (connection as ProjectContentItemType).items.filter(
            (item) => item.id === CREATE_AN_ACCOUNT_ID && item.active === true
        ).length === 1;

    function handleOnClickEvent(
        // eslint-disable-next-line
        item: any
    ) {
        switch (item.name.toLowerCase()) {
            case "Validate".toLowerCase(): {
                const connectionStepIndex = kioskWays.indexOf(
                    kioskWays.filter(
                        (page: any) => page?.name === "connection"
                    )[0]
                );
                console.log("button validate", connectionStepIndex);
                setIsCreateAnAccountSelected(true);
                setKeyboardInputsValues({});
                setNavigationIndex(connectionStepIndex);
                setIsAuthenticationErrorModalOpened(
                    !isAuthenticationErrorModalOpened
                );
                break;
            }
            case "Back".toLowerCase(): {
                setIsAuthenticationErrorModalOpened(
                    !isAuthenticationErrorModalOpened
                );
                break;
            }
        }
    }

    function handleCloseOnClick() {
        !isCreateAnAccountActive &&
            setIsAuthenticationErrorModalOpened(
                !isAuthenticationErrorModalOpened
            );
    }

    return (
        <Modal
            isOpen={isAuthenticationErrorModalOpened}
            ariaHideApp={false}
            onRequestClose={handleCloseOnClick}
            contentLabel="auth-error-modal"
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "30px",
                    color: "black",
                    height: "1230px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    gap: "50px",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <AuthenticationErrorHeader
                handleCloseOnClick={handleCloseOnClick}
                actionsButtons={actionsButtons as ActionsButtonsDesignType}
            />
            <div
                className="d-flex flex-column justify-content-between auth-main-container"
                onClick={handleCloseOnClick}
            >
                <AuthenticationErrorMain
                    isGodiaLoyalty={
                        selectedShopApp.fidelityType === GODIA_LOYALTY
                    }
                    isCreateAnAccountSelected={isCreateAnAccountSelected}
                    isCreateAnAccountActive={isCreateAnAccountActive}
                />
                <AuthenticationErrorFooter
                    handleOnClickEvent={handleOnClickEvent}
                    isCreateAnAccountActive={isCreateAnAccountActive}
                    isCreateAnAccountSelected={isCreateAnAccountSelected}
                    isGodiaLoyalty={
                        selectedShopApp.fidelityType === GODIA_LOYALTY
                    }
                />
            </div>
        </Modal>
    );
}
