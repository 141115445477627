import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export const Back = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    const { customerLanguage } = useSnapshot(kioskStore);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${props.width} ${props.height}`}
            {...props}
        >
            <g id="Groupe_85" data-name="Groupe 85">
                <g
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    fill="#eaeaea"
                    stroke="#141414"
                    strokeWidth="2"
                >
                    <rect
                        width={props.width}
                        height={props.height}
                        rx="9"
                        stroke="none"
                    />
                    <rect
                        x="1"
                        y="1"
                        width={parseInt(props.width as string) - 2}
                        height={parseInt(props.height as string) - 2}
                        rx="8"
                        fill="#eaeaea"
                    />
                </g>
                <text
                    id="Retour"
                    fill="#141414"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    x="50%"
                    y="50%"
                >
                    {t("Back", { lng: customerLanguage.name })}
                </text>
            </g>
        </svg>
    );
};
Back.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};
