/* eslint-disable @typescript-eslint/no-explicit-any */
import { CATALOGUE_MODE, Retrait_C_C, TCPOS } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { store } from "@store";

import {
    handlePutClickAndCollectOrder,
    handlePutCommand,
} from "@helpers/general";

import { LogoWrapper } from "@components/common/LogoWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { validateTCPOSCommand } from "@components/OrderTaking/Helpers";
import { setMiddlwareOrder } from "@components/OrderTaking/Store/feature/MiddlwareOrderSlice";

import { EmailSendedWithSuccessModalWrapper } from "@pages/Kiosk/EmailSendedWithSuccessModalWrapper";
import { Container } from "@pages/Kiosk/Steps/FinalMessageStep/Container";
import { ContainerContent } from "@pages/Kiosk/Steps/FinalMessageStep/ContainerContent";
import { OrderNumberWrapper } from "@pages/Kiosk/Steps/FinalMessageStep/OrderNumberWrapper";
import {
    kioskStore,
    setIsTicketNumberActive,
    setNavigationIndex,
} from "@pages/Kiosk/store";

export const FinalMessageStep = React.memo((): JSX.Element => {
    const { posEditor } = useSnapshot(store);
    const {
        selectedInformationMode,
        selectedRegulationModeValue,
        selectedSaleModeValue,
        project: {
            template: {
                pages: {
                    ways: { finalMessage },
                },
            },
        },
        selectedAuthMode,
        user,
        isCreatingVoucherAccepted,
        voucherAmount,
        chosenInformationMode,
        paymentModes,
        isOrderInsertedSuccessfully,
        isSendedEmailWithSuccessModalOpened,
        isSendMailWithPdfActive,
        clickAndCollectOrderId,
    } = useSnapshot(kioskStore);
    const dispatch = useDispatch();

    const {
        order,
        usedGifts,
        userRewards: { rewards },
    } = useSelector((state: any) => state.orderSlice);

    const middlwareOrder = useSelector(
        (state: any) => state.middlwareOrderSlice.middlwareOrder
    );

    function updatePaymentModes() {
        let localPaymentModes: PaymentModesType = [];
        const changeMachinePaymentMode = paymentModes.find(
            (mode: PaymentModeType) => mode.paymentMode === "changeMachine"
        );

        const changeMachinePaymentModeIndex = paymentModes.indexOf(
            changeMachinePaymentMode as PaymentModeType
        );

        localPaymentModes = paymentModes.map((paymentMode: PaymentModeType) => {
            return { ...paymentMode };
        });

        if (changeMachinePaymentMode !== undefined) {
            if (isCreatingVoucherAccepted) {
                const voucherMode = paymentModes.find(
                    (mode: PaymentModeType) => mode.paymentMode === "voucher"
                );

                if ((voucherMode as PaymentModeType).paymentAmount < 0) {
                    localPaymentModes[changeMachinePaymentModeIndex] = {
                        ...localPaymentModes[changeMachinePaymentModeIndex],
                        totalAmountDeposited:
                            changeMachinePaymentMode.paymentAmount +
                            Math.abs(
                                (voucherMode as PaymentModeType).paymentAmount
                            ),
                    };
                }
            } else {
                localPaymentModes[changeMachinePaymentModeIndex] = {
                    ...localPaymentModes[changeMachinePaymentModeIndex],
                    totalAmountDeposited:
                        changeMachinePaymentMode.paymentAmount +
                        changeMachinePaymentMode.data.renderAmount,
                };
            }
        }
        return localPaymentModes;
    }

    const updateMiddlewareOrder = React.useCallback(() => {
        if (selectedSaleModeValue !== Retrait_C_C) {
            dispatch(
                setMiddlwareOrder({
                    order,
                    selectedSaleModeValue,
                    selectedInformationMode,
                    selectedRegulationModeValue,
                    selectedAuthMode,
                    chosenInformationMode: {
                        type: chosenInformationMode.type,
                        data: {
                            infoModeUuId:
                                chosenInformationMode.data.infoModeUuId,
                            value: chosenInformationMode.data.value,
                        },
                    },
                    paymentModes: updatePaymentModes(),
                    phoneNumber: user.phoneNumber,
                    loyaltyRewards: rewards,
                    //! to review in case standard type
                    usedGifts,
                    amountRatio: user.amountRatio,
                })
            );
        }
    }, [dispatch]);

    React.useEffect(() => {
        if (!(TCPOS === posEditor)) {
            updateMiddlewareOrder();
        }
    }, [updateMiddlewareOrder]);

    React.useEffect(() => {
        console.log({ selectedSaleModeValue });
        if (
            !(TCPOS === posEditor) &&
            process.env.REACT_APP_MODE !== CATALOGUE_MODE
        ) {
            if (
                middlwareOrder.orderUuid !== "" &&
                selectedSaleModeValue !== Retrait_C_C
            ) {
                console.log("handle put command V2");
                handlePutCommand(middlwareOrder);
            }
            if (selectedSaleModeValue === Retrait_C_C) {
                console.log("handle put command for click and collect V2");
                handlePutClickAndCollectOrder(
                    clickAndCollectOrderId,
                    updatePaymentModes(),
                    isCreatingVoucherAccepted
                );
            }
        }
        if (
            TCPOS === posEditor &&
            selectedRegulationModeValue === "atTheCounter"
        ) {
            console.log("handle validate command for TCPOS");
            validateTCPOSCommand();
        }
    }, [isCreatingVoucherAccepted, voucherAmount, middlwareOrder.orderUuid]);

    React.useEffect(() => {
        if (
            isOrderInsertedSuccessfully ||
            process.env.REACT_APP_MODE === CATALOGUE_MODE
        ) {
            const timer: NodeJS.Timeout = setTimeout(
                () => setNavigationIndex(0),
                5000
            );

            return () => clearTimeout(timer);
        }
        setIsTicketNumberActive(
            ((finalMessage as PageType).footer as FooterType)
                .isTicketNumberActive
        );
    }, [isOrderInsertedSuccessfully]);

    return (
        <PreviewBackgroundWrapper>
            <Container>
                {isSendMailWithPdfActive === true &&
                isSendedEmailWithSuccessModalOpened ? (
                    <EmailSendedWithSuccessModalWrapper />
                ) : null}
                <LogoWrapper />
                <ContainerContent />
                <OrderNumberWrapper />
            </Container>
        </PreviewBackgroundWrapper>
    );
});

FinalMessageStep.displayName = "FinalMessageStep";
