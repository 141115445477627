import {
    CATALOGUE_MODE,
    NO_BUTTON_ROLE,
    TCPOS,
    VALIDATED_EXTENSION_FILE,
    YES_BUTTON_ROLE,
} from "@constants";
import { Dialog } from "@mui/material";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { store } from "@store";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { addItemCommandTcPos } from "@components/OrderTaking/Helpers";
import { addQuantityOrderItem } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function AddProductConfirmation({ open, onClose, onCancel, product }) {
    const { posEditor } = useSnapshot(store);
    const dispatchStore = useDispatch();
    const { isPrm } = useSnapshot(kioskStore);
    const { actionsButtons } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );

    const filteredButtons = actionsButtons.filter((item) => {
        return item.role === YES_BUTTON_ROLE || item.role === NO_BUTTON_ROLE;
    });

    function handleButtonOnCLickEvent(button) {
        if (button.name.toLowerCase() === "yes") {
            !(posEditor === TCPOS)
                ? dispatchStore(addQuantityOrderItem(product))
                : dispatchStore(addItemCommandTcPos(product));
            onClose();
        } else {
            onCancel();
            onClose();
        }
    }
    return (
        <Dialog
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    display: "flex!important",
                    gap: "50px!important",
                    alignItems: "center!important",
                    flexDirection: "column!important",
                    paddingTop: "0px!important",
                    paddingBottom: "0px!important",
                    color: "black!important",
                    height: "1335px!important",
                    width: "860px!important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    borderTop: `15px solid var(--global-color)!important`,
                    backgroundColor: "#FFFFFF!important",
                    borderRadius: "111px!important",
                    borderLeft: "1px solid #707070!important",
                    borderRight: "1px solid #707070!important",
                    borderBottom: "1px solid #707070!important",
                    top: isPrm ? "15%" : "unset",
                    right: "unset!important",
                    left: "unset!important",
                    background: `transparent linear-gradient(180deg, #FFFFFF 0%, var(--global-color) 300%) 0% 0% no-repeat padding-box`,
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
        >
            <div
                className="d-flex flex-column justify-content-center align-items-center w-100"
                style={{ height: "70%", gap: "20px" }}
            >
                <span
                    style={{
                        font: "normal normal 400 30px/40px Segoe UI",
                    }}
                >
                    {t("Would you like to keep")}
                </span>
                <span
                    style={{
                        height: "100px",
                        font: "normal normal 600 55px/60px Nunito Sans",
                    }}
                >
                    {t("The same composition?")}
                </span>
            </div>
            <div className="centered-items" style={{ height: "100%" }}>
                <div
                    className="image-product__clz"
                    style={{
                        height: "500px",
                        width: "500px",
                        backgroundImage: `url(${product.urlImage})`,
                    }}
                ></div>
            </div>
            <div
                style={{ height: "40%", gap: "30px", width: "100%" }}
                className="centered-items"
            >
                {filteredButtons
                    .sort((a, b) => {
                        return a.role > b.role ? -1 : 1;
                    })
                    .map((button) => {
                        const imageSrc = getImageContentById(button.id);

                        return (
                            <div
                                className="mx-2"
                                onClick={() => {
                                    handleButtonOnCLickEvent(button);
                                }}
                                key={button.id}
                            >
                                {VALIDATED_EXTENSION_FILE.includes(
                                    imageSrc.slice(-4)
                                ) ? (
                                    <img src={imageSrc} />
                                ) : (
                                    <DynamicSvgComponent
                                        nameSvg={imageSrc}
                                        width={"100%"}
                                        height={"35%"}
                                    />
                                )}
                            </div>
                        );
                    })}
            </div>
        </Dialog>
    );
}

AddProductConfirmation.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    product: PropTypes.object,
};
