import { CATALOGUE_MODE, VALIDATED_EXTENSION_FILE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import {
    getAlertActionButtonImageSrc,
    getGiveUpActionButtonImageSrc,
    handleLedControl,
} from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { clearMiddlwareOrder } from "@components/OrderTaking/Store/feature/MiddlwareOrderSlice";

import { kioskStore, setNavigationIndex } from "@pages/Kiosk/store";

export function CommandInsertionProblemModalWrapper(): JSX.Element {
    const {
        isPrm,
        project: {
            template: { content },
        },
        isCommandInsertionProblemModalOpened,

        connectedLedPort,
        isLedActive,
    } = useSnapshot(kioskStore);

    const dispatch = useDispatch();

    const alertImgSrc = getAlertActionButtonImageSrc(
        content.actionsButtons as ActionsButtonsDesignType
    );

    // function handleOnClickEvent() {
    //     const meansOfPaymentStepIndex = kioskWays.indexOf(
    //         kioskWays.filter((step) => step.name === "meansOfPayment")[0]
    //     );
    //     const orderTakingStepIndex = kioskWays.indexOf(
    //         kioskWays.filter((step) => step.name === "orderTaking")[0]
    //     );

    //     const localPaymentModes = paymentModes.filter(
    //         (mode) => mode.paymentMode === "loyalty"
    //     );

    //     setPaymentModes(localPaymentModes);
    //     setIsCommandInsertionProblemModalOpened(false);
    //     setIsAmountReturned(false);
    //     setLeftToPayAfterCashMachineValidation(0);
    //     if (isLedActive) {
    //         handleLedControl(connectedLedPort, "Orange");
    //     }
    //     if (
    //         meansOfPaymentStepIndex !== -1 &&
    //         kioskWays[meansOfPaymentStepIndex].skipped === false
    //     ) {
    //         setNavigationIndex(meansOfPaymentStepIndex);
    //     } else {
    //         // setIsOrderTakingPreviousStep(true);
    //         setNavigationIndex(orderTakingStepIndex);
    //     }
    // }

    function handleabortOnClick() {
        setNavigationIndex(0);
        dispatch(clearMiddlwareOrder());
    }

    if (isLedActive) {
        handleLedControl(connectedLedPort, "Red");
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isCommandInsertionProblemModalOpened}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (content.generalDesign as GeneralDesignType).colors[0]
                            .content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",

                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "350px" }}
            >
                <img alt="alert image" src={alertImgSrc} />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "150px" }}
            >
                <span style={{ font: "normal normal 600 55px/60px Segoe UI" }}>
                    {t("Technical problem")}
                </span>
            </div>
            <div style={{ height: "100px" }} />
            <div
                className="d-flex justify-content-center align-items-center text-center  w-100"
                style={{ height: "200px" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                >
                    {t(
                        "Error when inserting order. Please contact the cashier for assistance."
                    )}
                </span>
            </div>

            <div style={{ height: "100px" }} />

            <div style={{ height: "100px" }} />
            <div
                style={{ height: "120px" }}
                className="d-flex justify-content-center align-items-center"
                onClick={handleabortOnClick}
            >
                {VALIDATED_EXTENSION_FILE.includes(
                    getGiveUpActionButtonImageSrc(
                        content.actionsButtons as ActionsButtonsDesignType
                    ).slice(-4)
                ) ? (
                    <img
                        alt="back button"
                        src={getGiveUpActionButtonImageSrc(
                            content.actionsButtons as ActionsButtonsDesignType
                        )}
                        style={{
                            width: isPrm ? "281px" : "350px",
                            height: isPrm ? "70" : "87px",
                        }}
                    />
                ) : (
                    <DynamicSvgComponent
                        nameSvg={getGiveUpActionButtonImageSrc(
                            content.actionsButtons as ActionsButtonsDesignType
                        )}
                        width={isPrm ? "281px" : "350px"}
                        height={isPrm ? "70" : "87px"}
                    />
                )}
            </div>
        </Modal>
    );
}
