import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore as store } from "@pages/Kiosk/store";

export function SubStepsInformationMessage(): JSX.Element {
    const {
        subStepInformationMessageItems,
        customerLanguage,
        isSubStepInformationMessageActive,
    } = useSnapshot(store);

    return (
        <React.Fragment>
            {isSubStepInformationMessageActive && (
                <span
                    className="two-lines-preview-text w-100"
                    style={{
                        fontSize: "45px",
                        textAlign: "center",
                        lineHeight: "3rem",
                    }}
                >
                    {
                        subStepInformationMessageItems[customerLanguage.name]
                            .content
                    }
                </span>
            )}
        </React.Fragment>
    );
}
